import React from "react";
import { useState } from "react";
import moment from "moment";
import * as utils from "../../Utils/comman";
import { useEffect } from "react";
import DataTable from "react-data-table-component";

function FeesStructure(props) {
  const [selectedYear, setSelectedYear] = useState();
  const [selectedClass, setSelectedClass] = useState();
  const [classes, setClasses] = useState([]);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isRow, setIsRow] = useState(false);
  const [data, setData] = useState([]);
  const [totalAmount, settotalAmount] = useState();
  let newDate = new Date();
  let date = moment(newDate).format("DD-MM-YYYY");
  let currentYear = newDate.getFullYear();
  let nextYear = newDate.getFullYear() + 1;
  let nextToNextYear = newDate.getFullYear() + 2;

  let startYear = 2021;
  var years = [];
  for (var i = startYear; i <= currentYear + 1; i++) {
    years.push({
      label: `${i}-${i + 1}`,
      value: `${i}-${i + 1}`,
    });
  }

  const columns = [
    {
      name: "Label Name",
      selector: (row) => row.label_name,
    },
    {
      name: "Status",
      selector: (row) => row.order_status,
    },
    {
      name: "Transaction Date",
      selector: (row) =>
        !row.createdAt ? "" : moment(row.createdAt).format("DD-MM-YYYY"),
    },
    {
      name: "Remark",
      selector: (row) => row.remark,
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
    },
  ];

  const handleBackClick = (e) => {
    const propdata = {
      mode: "list",
    };
    props.onChange(propdata);
  };

  const handleYear = (e) => {
    setSelectedYear(e.target.value);
    setIsSubmit(true);
  };
  const handleChange = (e) => {
    setSelectedClass(e.target.value);
    setIsSubmit(true);
  };

  useEffect(() => {
    getClassList();
  }, []);

  function getClassList() {
    utils.callAPI("get", "classes/list", "").then((res) => {
      if (typeof res !== "undefined") {
        if (Object.keys(res.result).length > 0) {
          setClasses(
            res.result.map(({ id, className }) => ({
              label: className,
              value: id,
            }))
          );
        }
      }
    });
  }

  useEffect(() => {
    if (selectedYear !== undefined && selectedClass !== undefined && isSubmit) {
      utils
        .callAPI(
          "get",
          `fees/student-paid-fees-list?studentId=${props.rowData.id}&classId=${selectedClass}&year=${selectedYear}`,
          ""
        )
        .then((res) => {
          if (typeof res !== "undefined") {
            if(res.length > 1){
              const total = res.reduce((a,b)=> Number(a.amount) + Number(b.amount))
            settotalAmount(total);
            }
            setIsSubmit(false);
            setData(res);
            if(res.length > 1){
              setIsRow(true);
            }
          }
        });
    }
  }, [isSubmit, selectedClass, selectedYear, props.rowData.id]);

  useEffect(() => {
    if (isRow) {
      data.push({
        remark: "Total",
        amount: `${totalAmount}`,
      });
      setIsRow(false);
    }
  }, [isRow]);

  return (
    <div className="section-body">
      <div className="card card-primary">
        <div className="card-header">
          <h4>Fees Structure List</h4>
          <div className="card-header-action">
            <button
              className="btn btn-outline-primary"
              onClick={handleBackClick}
            >
              Back
            </button>
          </div>
        </div>
        <div className="card-body">
          <div className="font-weight-600 mb-3">
            <span className="text-body">
              Student Name:-{" "}
              {`${props.rowData.firstName} ${props.rowData.lastName}`}
            </span>
          </div>
          <div className="row align-items-center">
            <div className="col-md-3">
              <label htmlFor="class">Year</label>
              <select
                id="class"
                name="class"
                className="form-control"
                onChange={handleYear}
              >
                <option value="">Select Year</option>
                {years.map(({ label, value }, id) => (
                  <option key={label} value={value}>
                    {label}
                  </option>
                ))}
              </select>
              {/* <div className="col-auto">
                  {error.year ? (
                    <span className="invalid-feedback d-block">
                      {error.year}
                    </span>
                  ) : null}
                </div> */}
            </div>
            <div className="col-md-3">
              <label htmlFor="class">Class</label>
              <select
                id="class"
                name="class"
                className="form-control"
                onChange={handleChange}
              >
                <option value="">Select Class</option>
                {classes.reverse().map(({ label, value }, id) => (
                  <option key={label} value={value}>
                    {label}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="row align-items-center justify-content-end">
            <DataTable
              columns={columns}
              data={data}
              highlightOnHover
              responsive
              paginationServer
              persistTableHead
              subHeader
              // subHeaderComponent={subHeaderComponent}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default FeesStructure;
