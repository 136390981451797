import React, { useState, useEffect, useCallback, useMemo } from "react";
import * as utils from "../../Utils/comman";
import ButtonLoader from "../ButtonLoader";
import DataTable from 'react-data-table-component';
import swal from 'sweetalert';
import FilterComponent from "../FilterComponent";
function ViewTeacher(props) {
    const [teachers, setTeachers] = useState({});
    const [page, setPage] = useState(1);
    const countPerPage = 10;
    const [filterText, setFilterText] = useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const columns = [
        {
            name: 'Teacher Name',
            cell: row => <>
            {(row.typeId === "1" || row.typeId === "2") ? <span className="text-primary">{row.firstName+" "+row.lastName}</span> : row.firstName+" "+row.lastName}
            </>,
        },
        {
            name: 'Action',
            cell: row => <>
            {(row.typeId === "1" || row.typeId === "2") ? '' : <button className='btn btn-sm btn-outline-danger' onClick={showDelete(row)}><i className="fas fa-trash-alt"></i></button>}
            </>
        },
    ];
    const handleBackClick = (e) => {
        const propdata = {
            mode: "list",
        };
        props.onChange(propdata);
    };
    const handleAddClick = (e) => {
        const propdata = {
          mode: "teacherAdd",
          rowData: props.rowData.id,
        };
        props.onChange(propdata);
      };
    const handelEdit = useCallback(
        
        row => async () => {
            //
            const propdata = {
                'mode': 'edit',
                'rowData': row
            }
            props.onChange(propdata);
        
        },
    );
    useEffect(() => {
        getTeachersList(props.rowData.id);
    }, [page, filterText]);
    const subHeaderComponent = useMemo(() => {
        
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText("");
            }
        };
        
        return (
            <FilterComponent
            onFilter={e => setFilterText(e.target.value)}
            onClear={handleClear}
            filterText={filterText}
            />
            
        );
    }, [filterText, resetPaginationToggle]);
    const getTeachersList = (sectionId) => {
                
        utils.callAPI('get', `section/teachers?sectionId=${sectionId}&page=${page}&per_page=${countPerPage}&sear_text=${filterText}`, '').then(
            res => {
                
                if (typeof res !== 'undefined') {
                    setTeachers(res);
                }else{
                    setTeachers({});        
                }

            }
        ).catch(err => {
            setTeachers({});
        });
    }
    const showDelete = useCallback(
        row => async () => {
            swal({
                title: 'Are you sure?',
                text: 'Once deleted, you will not be able to recover this data!',
                icon: 'warning',
                buttons: ["No", "Yes"],
                dangerMode: true,
            })
            .then((willDelete) => {
                if (willDelete) {
                    utils.callAPI('get', `section/teachers/remove?teacherId=${row.id}&sectionId=${props.rowData.id}`, '').then(
                        res => {
                            
                            if (typeof res !== 'undefined') {
                                getTeachersList(props.rowData.id);
                            }
            
                        }
                    )
                } 
            });
        }
    )
    return (
        <>
            <div className="section-body">
                <div className="card card-primary">
                    <div className="card-header">
                        <h4>View Teachers</h4>
                        <div className="card-header-action">
                            <button className="btn btn-primary mr-2" onClick={handleAddClick}>
                                Add Teacher
                            </button>
                            <button
                                className="btn btn-outline-primary"
                                onClick={handleBackClick}
                            >
                                Back
                            </button>
                        </div>
                    </div>
                    <div className="card-body">
                        
                        <DataTable
                            columns={columns}
                            data={teachers.result}
                            highlightOnHover
                            responsive
                            pagination
                            paginationServer
                            paginationTotalRows={teachers.total}
                            paginationPerPage={countPerPage}
                            paginationComponentOptions={{
                                noRowsPerPage: true
                            }}
                            onChangePage={page => setPage(page)}
                            persistTableHead
                            subHeader
                            subHeaderComponent={subHeaderComponent}
                        />
                        
                    </div>
                </div>
            </div>
        </>
    )
}
export default ViewTeacher;