import axios from "axios";

const instance = axios.create({
  // baseURL: "https://api.prastaratech.com/api",
    //  baseURL: "https://prastaratech.com/dev_api/api",
    //  baseURL: "https://api.prastaratech.com/api",
    baseURL: "https://api.greatminds.live/api",

  headers: {
    "Content-Type": "application/json",
    //Authorization: "Bearer " + localStorage.getItem("auth_access_token"),
    //'Authorization': 'Bearer 123'
  },
});
//baseURL: "http://localhost/great-minds-web-api/api",

export default instance;
