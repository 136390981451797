import moment from "moment";
import React, { useEffect, useState } from "react";
import { useCallback } from "react";
import { useMemo } from "react";
import { Button, Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import swal from "sweetalert";
import * as utils from "../../Utils/comman";
import FilterComponent from "../FilterComponent";
import { cloneDeep } from "lodash";

function List(props) {
  // const years = [
  //   { label: "2017-2018", value: "2017-2018" },
  //   { label: "2018-2019", value: "2018-2019" },
  //   { label: "2019-2020", value: "2019-2020" },
  //   { label: "2020-2021", value: "2020-2021" },
  //   { label: "2021-2022", value: "2021-2022" },
  //   { label: "2022-2023", value: "2022-2023" },
  // ];

  let newDate = new Date();
  let date = moment(newDate).format("DD-MM-YYYY");
  let currentYear = newDate.getFullYear();
  let nextYear = newDate.getFullYear() + 1;
  let nextToNextYear = newDate.getFullYear() + 2;

  let startYear = 2021;
  var years = [];
  for (var i = startYear; i <= currentYear + 1; i++) {
    years.push({
      label: `${i}-${i + 1}`,
      value: `${i}-${i + 1}`,
    });
  }

  // console.log(lyears);

  // const years = [
  //   {
  //     label: `${currentYear}-${nextYear}`,
  //     value: `${currentYear}-${nextYear}`,
  //   },
  //   {
  //     label: `${nextYear}-${nextToNextYear}`,
  //     value: `${nextYear}-${nextToNextYear}`,
  //   },
  // ];

  const [classes, setClasses] = useState([]);
  const [selectedYear, setSelectedYear] = useState(`${currentYear}-${currentYear + 1}`);
  const [selectedClass, setSelectedClass] = useState();
  const [page, setPage] = useState(1);
  const countPerPage = 10;
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState({});
  const [feesTermsList, setFeestermsList] = useState({});
  const [structureId, setStructureId] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [show, setShow] = useState(false);
  const [modelFields, setModelFields] = useState([]);
  const [isModal, setIsModal] = useState(false);
  const [isParticular, setIsparticular] = useState(false);
  const columns = [
    {
      name: "Terms",
      selector: (row) => row.label_name,
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <button
            className="btn btn-sm btn-outline-primary"
            onClick={handleShow(row)}
          >
            <i class="fas fa-eye"></i>
          </button>
          {/* &nbsp;
          <button
            className="btn btn-sm btn-outline-danger"
            onClick={showDelete(row)}
          >
            <i className="fas fa-trash-alt"></i>
          </button> */}
        </>
      ),
    },
  ];

  const handleClose = () => {
    setModelFields([]);
    setShow(false);
  };

  const handleShow = useCallback((row) => async () => {
    const bodyFormData = new FormData();
    bodyFormData.append("year", selectedYear);
    bodyFormData.append("classId", selectedClass);
    bodyFormData.append("typeId", row.id);
    utils
      .callAPI("post", "payment/view_structure", bodyFormData)
      .then((res) => {
        if (typeof res !== "undefined") {
          // res.map((mField, id) => {
          setModelFields(res);
          setIsModal(true);
          // });
        }
      });
  });

  useEffect(() => {
    if (isModal) {
      setShow(() => !show);
    }
    setIsModal(false);
  }, [isModal]);
  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  useEffect(() => {
    getClassList();
  }, []);

  function getClassList() {
    utils.callAPI("get", "classes/list", "").then((res) => {
      if (typeof res !== "undefined") {
        if (Object.keys(res.result).length > 0) {
          setClasses(
            res.result.map(({ id, className }) => ({
              label: className,
              value: id,
            }))
          );
        }
      }
    });
  }

  // const showDelete = useCallback((row) => async () => {
  //   swal({
  //     title: "Are you sure?",
  //     text: "Once deleted, you will not be able to recover this data!",
  //     icon: "warning",
  //     buttons: ["No", "Yes"],
  //     dangerMode: true,
  //   }).then((willDelete) => {
  //     if (willDelete) {
  //       utils
  //         .callAPI("get", `payment/delete_structure?id=${Number(row.id)}`,"")
  //         .then((res) => {
  //           if (typeof res !== "undefined") {
  //            setIsSubmitting(true)
  //           }
  //         });
  //     }
  //   });
  // });

  const handleAddClick = (e) => {
    const propdata = {
      mode: "add",
    };
    props.onChange(propdata);
  };

  const handleChange = (e) => {
    setSelectedClass(e.target.value);
    setIsSubmitting(true);
  };

  const handleYear = (e) => {
    setSelectedYear(e.target.value);
  };

  useEffect(() => {
    if (isSubmitting) {
      utils
        .callAPI(
          "get",
          `/payment/list-fees-structure?year=${selectedYear}&classId=${selectedClass}`,
          ""
        )
        .then((res) => {
          if (typeof res !== "undefined") {
            setError("");
            setFeestermsList(res);
            res.result.map((typeData, id) => {
              setStructureId(typeData.structure_id);
            });
            setIsEdit(true);
            if (res.result.length == 0) {
              setFeestermsList({});
              setStructureId();
              setIsEdit(false);
            }
          } else {
          }
        });
    }
    setIsSubmitting(false);
  }, [isSubmitting]);

  const handelEdit = useMemo(() => async () => {
    setError(validate());
  });

  function validate() {
    let error = {};
    if (!selectedClass) {
      error.class = "Please select class";
    }

    if (!selectedYear) {
      error.year = "Please select Year";
    } else {
      error = "";
      const propdata = {
        mode: "edit",
        rowData: structureId,
        isDiscount: true,
      };
      props.onChange(propdata);
    }

    return error;
  }

  return (
    <>
      <div className="section-body">
        <div className="card card-primary">
          <div className="card-header">
            <h4>Fees Structure</h4>
            <div className="card-header-action">
              <button className="btn btn-primary" onClick={handleAddClick}>
                Add Fees Structure
              </button>
            </div>
          </div>
          <div className="card-body">
            <div className="row align-items-center">
              <div className="col-md-3">
                <label htmlFor="class">Year</label>
                <select
                  id="class"
                  name="class"
                  className="form-control"
                  onChange={handleYear}
                  defaultValue={`${currentYear}-${currentYear + 1}`}
                >
                  <option value="">Select Year</option>
                  {years.map(({ label, value }, id) => (
                    <option key={label} value={value}>
                      {label}
                    </option>
                  ))}
                </select>
                <div className="col-auto">
                  {error.year ? (
                    <span className="invalid-feedback d-block">
                      {error.year}
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="col-md-3">
                <label htmlFor="class">Class</label>
                <select
                  id="class"
                  name="class"
                  className="form-control"
                  onChange={handleChange}
                >
                  <option value="">Select Class</option>
                  {classes.reverse().map(({ label, value }, id) => (
                    <option key={label} value={value}>
                      {label}
                    </option>
                  ))}
                </select>
                <div className="col-auto">
                  {error.class ? (
                    <span className="invalid-feedback d-block">
                      {error.class}
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="col-auto mt-4">
                {/* <button
                      type="submit"
                      className="btn btn-primary"
                      onClick={handelEdit}
                    >
                      Edit 
                  </button>*/}
                {isEdit ? (
                  <button
                    className="btn btn-sm btn-outline-primary"
                    onClick={handelEdit}
                  >
                    <i className="fas fa-edit"></i>
                  </button>
                ) : null}
              </div>
            </div>
            <DataTable
              columns={columns}
              data={feesTermsList.result}
              highlightOnHover
              responsive
              pagination
              paginationServer
              paginationTotalRows={feesTermsList.total}
              paginationPerPage={countPerPage}
              paginationComponentOptions={{
                noRowsPerPage: true,
              }}
              onChangePage={(page) => setPage(page)}
              persistTableHead
              subHeader
              subHeaderComponent={subHeaderComponent}
            />
          </div>
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Particulars
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="border-top my-1"></div>
          <div className="row align-items-center ">
            <div className="col-md-6 text-center">
              <label htmlFor="particulars">Particulars</label>
            </div>
            <div className="col-md-6 text-center">
              <label htmlFor="particulars">Amount</label>
            </div>
          </div>
          <div class="border-top my"></div>
          <div>
            {modelFields?.map((modelField, id) => (
              <div className="row align-items-center mt-2">
                <div className="col-md-6 text-center">
                  <span>{modelField.particulars_name}</span>
                </div>
                <div className="col-md-6 text-center">
                  <span>{modelField.amount}</span>
                </div>
              </div>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default List;
