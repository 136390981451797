import React, {useState, useEffect} from 'react';
import Logo from "../Components/Logo";
import Header from "../Components/Header";
import Footer from '../Components/Footer';
import Sidebar from '../Components/Sidebar';
function Dashboard() {
    
    return (
        <>
            <Header />
            <Sidebar />
            <div className="main-content">
                <section className="section">
                    <div className="section-header">
                        <h1>Dashboard</h1>
                    </div>
                    <div className="section-body">
                        <div className="card">
                            <div className="card-body">
                                
                            </div>
                        </div>
                    </div>
                </section>
            </div>
                
            <Footer />
        </>
    );
}
export default Dashboard