import React from "react";
import List from "../Components/Subjects/List";
import Add from "../Components/Subjects/Add";

import Footer from "../Components/Footer";
import Header from "../Components/Header";
import Sidebar from "../Components/Sidebar";
import { useState } from "react";

function Subjects() {
  const [comp, setComp] = useState("list");
  const [rowData, setRowData] = useState();
  function handleChange(newValue) {
    setComp(newValue.mode);
    setRowData(newValue.rowData);
  }
  return (
    <>
      <Header />
      <Sidebar />
      <div className="main-content">
        <section className="section">
          <div className="section-header">
            <h1>Subjects</h1>
          </div>
          {comp === "list" ? (
            <List comp={comp} onChange={handleChange} rowData={rowData} />
          ) : (
            <Add comp={comp} onChange={handleChange} rowData={rowData} />
          )}
        </section>
      </div>
      <Footer />
    </>
  );
}

export default Subjects;
