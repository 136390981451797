import React, { useState, useEffect }  from 'react'
import { useNavigate  } from "react-router-dom";
import * as utils from "../Utils/comman";
function Header () {
    const navigate  = useNavigate();
    const [userData, setUserData] = useState("");
    const [schoolData, setSchoolData] = useState("");
    const handleLogout = (e) => {
        sessionStorage.removeItem('auth_access_token');
        //localStorage.removeItem('userData');
        navigate("/");
    }
    useEffect(() => {
        setUserData(utils.getCurrentUser());
        setSchoolData(utils.getCurrentSchool());
    },[]);
    
    return(
        <>
            <div className="navbar-bg"></div>
            <nav className="navbar navbar-expand-lg main-navbar">
                <form className="form-inline">
                    <ul className="navbar-nav mr-3">
                        <li><a href="#" data-toggle="sidebar" className="nav-link nav-link-lg"><i className="fas fa-bars"></i></a></li>
                        <li><a href="#" data-toggle="search" className="nav-link nav-link-lg d-sm-none"><i className="fas fa-search"></i></a></li>
                    </ul>
                    
                </form>
                <div className='mr-auto pt-3 text-white'>
                    <h3>{schoolData.schoolName}</h3>
                </div>
                <ul className="navbar-nav navbar-right">
                    <li className="dropdown show"><a href="#" data-toggle="dropdown" className="nav-link dropdown-toggle nav-link-lg nav-link-user" aria-expanded="true">
                        {/* <img alt="image" src="../assets/img/avatar/avatar-1.png" className="rounded-circle mr-1"/> */}
                        <div className="d-sm-none d-lg-inline-block">Hi, {userData.firstName+" "+userData.lastName}</div></a>
                        <div className="dropdown-menu dropdown-menu-right">
                            {/* <div className="dropdown-title">Logged in 5 min ago</div> */}
                            {/* <a href="features-profile.html" className="dropdown-item has-icon">
                                <i className="far fa-user"></i> Profile
                            </a>
                            <a href="features-activities.html" className="dropdown-item has-icon">
                                <i className="fas fa-bolt"></i> Activities
                            </a>
                            <a href="features-settings.html" className="dropdown-item has-icon">
                                <i className="fas fa-cog"></i> Settings
                            </a> */}
                            <div className="dropdown-divider"></div>
                            <button onClick={handleLogout} className="dropdown-item has-icon text-danger">
                                <i className="fas fa-sign-out-alt"></i> Logout
                            </button>
                        </div>
                    </li>
                </ul>
            </nav>
            
        </>
    );
}
export default Header