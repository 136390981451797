import React, { useState, useEffect } from 'react'
import * as utils from '../../Utils/comman';
import ButtonLoader from '../ButtonLoader';
function Add(props) {
    
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isValidate, setIsValidate] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isSameSchool, setIsSameSchool] = useState(false);
   
    const [values, setValues] = useState({
        rowid: '0',
        empNo: '',
        firstName: '',
        lastName: '',
        designation: '',
        mobileNo: '',
        schoolId: 0,
        userId: 0,
    });
    const [errors, setErrors] = useState({});
    const handleBackClick = (e) => {
        const propdata = {
            'mode': 'list',
        }
        props.onChange(propdata);
    }
    const handleTextChange = e => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value
        });
        delete errors[name];
        // setErrors({
        //     ...errors,
        //     [name]: ''
        // });
        
    };
    const handleFormSubmit = (e) => {
        e.preventDefault();
        setErrors(validate(values));
        setIsSubmitting(true);
    }
    const handleValidate = (e) => {
        setIsValidate(true);
    }
    useEffect(() => {
        if (isSubmitting && Object.keys(errors).length === 0) {            
            var bodyFormData = new FormData();
            bodyFormData.append('id', values.rowid);
            bodyFormData.append('empNo', values.empNo);
            bodyFormData.append('firstName', values.firstName);
            bodyFormData.append('lastName', values.lastName);
            bodyFormData.append('designation', values.designation);
            bodyFormData.append('mobileNo', values.mobileNo);
            bodyFormData.append('schoolId', values.schoolId);
            bodyFormData.append('userId', values.userId);

            utils.callAPI('post', 'employee/save', bodyFormData).then(
                res => {
                    setIsSubmitting(false);
                    if (typeof res !== 'undefined') {
                        const propdata = {
                            'mode': 'list',
                        }
                        props.onChange(propdata);
                    }
                }
            )
        }else{
            setIsSubmitting(false);
        }
    }, [isSubmitting]);
    useEffect(() => {
        if (isValidate) {
            utils.callAPI('get', `employee/validate?mobileNo=${values.mobileNo}`).then(
                res => {
                    
                    if (typeof res !== 'undefined') {
                       
                        if(Object.keys(res).length > 0){
                            
                            let schoolIds = res['schoolIds'];
                            const schoolIdsArr = schoolIds.split(',');
                            let currentSchool = localStorage.getItem('currentSchool');
                            if(schoolIdsArr.includes(currentSchool)){
                                setIsSameSchool(true);
                                values['schoolId'] = currentSchool;
                            } else {
                                values['schoolId'] = 0;
                            }
                            values['userId'] = res.id;
                            values['empNo'] = res.empNo;
                            values['firstName'] = res.firstName;
                            values['lastName'] = res.lastName;
                        }else{
                            setIsSameSchool(true);
                            //utils.showMessage("Mobile no is not exist", "Success");
                        }
                    }
                }
            )
            setIsValidate(false);
        }
    }, [isValidate]);
    useEffect(() => {
        
        if(props.rowData && Object.keys(props.rowData).length > 0) {
            values['rowid'] = props.rowData.id;
            values['empNo'] = props.rowData.empNo;
            values['firstName'] = props.rowData.firstName;
            values['lastName'] = props.rowData.lastName;
            values['designation'] = props.rowData.designation;
            values['mobileNo'] = props.rowData.mobileNo;
            values['schoolId'] = localStorage.getItem('currentSchool');
            setIsEdit(true)
        }
    }, []);
    return (
       
            <div className="section-body">
                <div className="card card-primary">
                    <div className="card-header">
                        <h4>{isEdit ? "Edit Employee":"Add Employee"}</h4>
                        <div className="card-header-action">
                            <button className="btn btn-outline-primary" onClick={handleBackClick}>
                                Back
                            </button>
                        </div>
                    </div>
                    <div className="card-body">
                        
                            <div className="row">
                                <div className='col-md-6 offset-md-3'>
                                    <form className={(Object.keys(errors).length > 0) ? "needs-validation was-validated" : "needs-validation"} noValidate onSubmit={handleFormSubmit} >
                                        <div className="form-group">
                                            <label htmlFor="mobileNo">Mobile No</label>
                                            <input id="mobileNo" type="text" className="form-control" name="mobileNo" value={values.mobileNo} onChange={handleTextChange} required disabled={isEdit}/>
                                            {errors.mobileNo && <div className="invalid-feedback d-block">{errors.mobileNo}</div>}
                                        </div>
                                        {!isEdit &&
                                        <div className='form-group'>
                                            <button className="btn btn-outline-primary btn-sm" onClick={handleValidate}>
                                                Validate
                                            </button>
                                        </div>}
                                        <div className="form-group">
                                            <label htmlFor="empNo">Employee No.</label>
                                            <input id="empNo" type="text" className="form-control" name="empNo" value={values.empNo} onChange={handleTextChange} disabled={(isEdit) ? false : (isSameSchool) ? false : true} required />
                                            {errors.empNo && <div className="invalid-feedback d-block">{errors.empNo}</div>}
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="firstName">First Name</label>
                                            <input id="firstName" type="text" className="form-control" name="firstName" value={values.firstName} onChange={handleTextChange} disabled={(isEdit) ? false : (isSameSchool) ? false : true} required />
                                            {errors.firstName && <div className="invalid-feedback d-block">{errors.firstName}</div>}
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="lastName">Last Name</label>
                                            <input id="lastName" type="text" className="form-control" name="lastName" value={values.lastName} onChange={handleTextChange} disabled={(isEdit) ? false : (isSameSchool) ? false : true} required />
                                            {errors.lastName && <div className="invalid-feedback d-block">{errors.lastName}</div>}
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="designation">Designation</label>
                                            <input id="designation" type="text" className="form-control" name="designation" value={values.designation} onChange={handleTextChange} required />
                                            {errors.designation && <div className="invalid-feedback d-block">{errors.designation}</div>}
                                        </div>
                                        
                                        <div className="form-group text-right">
                                            <button type="submit" className="btn btn-primary btn-lg btn-icon icon-right d-flex align-items-center justify-content-center" disabled={isSubmitting}>
                                                {(isSubmitting) ? (<ButtonLoader />) : null}<span className={(isSubmitting) ? 'ml-2' : ''}>Submit</span>
                                            </button>
                                        </div>
                                        </form>
                                </div>

                            </div>
                       
                    </div>
                </div>
            </div>
       
    )
}
function validate(values){
    
    let errors = {};
    if (!values.empNo) {
        errors.empNo = 'Please fill employee number';
    }
    // else{
    //     delete errors['empNO']
    // }
    
    if (!values.firstName) {
        errors.firstName = 'Please fill first name';
    }
    // else{
    //     errors.firstName = '';
    // }
    if (!values.lastName) {
        errors.lastName = 'Please fill last name';
    }
    // else{
    //     errors.lastName = '';
    // }
    if (!values.designation) {
        errors.designation = 'Please fill designation';
    }
    // else{
    //     errors.designation = '';
    // }
    if (!values.mobileNo) {
        errors.mobileNo = 'Please fill mobile number';
    }
    // else{
    //     errors.mobileNo = '';
    // }


    // if (!values.password) {
    //     errors.password = 'Password is required';
    // }
    //   } else if (values.confirmpassword !== values.password) {
    //     errors.confirmpassword = 'Confirm Password do not match';
    //   }
      
    return errors;
}
export default Add