import React, {useState, useEffect} from 'react';
import Header from "../Components/Header";
import Footer from '../Components/Footer';
import Sidebar from '../Components/Sidebar';
import * as utils from "../Utils/comman";
import ButtonLoader from '../Components/ButtonLoader';
import List from '../Components/Employees/List';
import Add from '../Components/Employees/Add';
//import $ from 'jquery';
function Employees() {
    const [comp, setComp] = useState('list');
    const [rowData, setRowData] = useState();
    
    function handleChange(newValue) {
        setComp(newValue.mode);
        setRowData(newValue.rowData);
    }
    const renderComp = () => {
        if (comp === 'list') {
          return (<List comp={comp} onChange={handleChange}/>);
        } else {
          return (<Add comp={comp} onChange={handleChange}/>);
        }
    }
    return (
        <>
            <Header />
            <Sidebar />
            <div className="main-content">
                <section className="section">
                    <div className="section-header">
                        <h1>Employees</h1>
                    </div>
                    
                    {(comp === 'list') ? <List comp={comp} onChange={handleChange} rowData={rowData}/> : <Add comp={comp} rowData={rowData} onChange={handleChange}/>}
                    
                </section>
            </div>
            <Footer />
        </>
    )
}
export default Employees