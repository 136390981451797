import React from 'react'
import { useState } from 'react';
import Add from '../Components/FeesStructure/Add';
import List from '../Components/FeesStructure/List';
import Footer from '../Components/Footer';
import Header from '../Components/Header';
import Sidebar from '../Components/Sidebar';

function FeesStructure() {
    const [comp, setComp] = useState('list');
    const [rowData, setRowData] = useState();
    function handleChange(newValue) {
        setComp(newValue.mode);
        setRowData(newValue.rowData);
    }
    return (
        <>
            <Header />
            <Sidebar />
            <div className="main-content">
                <section className="section">
                    <div className="section-header">
                        <h1>Fees Structure</h1>
                    </div>
                    {(comp === 'list') ? <List comp={comp} onChange={handleChange} rowData={rowData}/> :  
                    <Add comp={comp} rowData={rowData} onChange={handleChange}/>
                    }
                </section>
            </div>
            <Footer />
        </>
    );
}

export default FeesStructure