import React, {useState, useEffect} from 'react';
import Header from "../Components/Header";
import Footer from '../Components/Footer';
import Sidebar from '../Components/Sidebar';
import * as utils from "../Utils/comman";
import ButtonLoader from '../Components/ButtonLoader';
import List from '../Components/Students/List';
import Add from '../Components/Students/Add';
import FeesStructure from '../Components/Students/FeesStructure';
//import $ from 'jquery';
function Students() {
    const [comp, setComp] = useState('list');
    const [rowData, setRowData] = useState();
    function handleChange(newValue) {
        setComp(newValue.mode);
        setRowData(newValue.rowData);
    }
    return (
        <>
            <Header />
            <Sidebar />
            <div className="main-content">
                <section className="section">
                    <div className="section-header">
                        <h1>Students</h1>
                    </div>
                    {(comp === 'list') ? <List comp={comp} onChange={handleChange} rowData={rowData}/> :
                    (comp === 'fees-structure') ? <FeesStructure comp={comp} onChange={handleChange} rowData={rowData}/>:
                    <Add comp={comp} rowData={rowData} onChange={handleChange}/>}
                    
                </section>
            </div>
            <Footer />
        </>
    );
}
export default Students