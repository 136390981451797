import React from 'react';
import { PrimaryMenu } from './PrimaryMenu';
import { NavLink } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
function PrimaryNavebar() {
    return(
      <>
        <ul className="sidebar-menu">
            
            {/* <li><a className="nav-link" href="#"><i className="far fa-square"></i> <span>Dashboard</span></a></li> */}
            {PrimaryMenu.map((item, index) => {
                if(!('comp' in item)){
                    return (
                        <li key={index}>
                            <a href={item.externalurl} className={item.cName}>{item.title}</a>
                        </li>
                    );
                }
                else{
                    if(!('submenu' in item)){
                        return (
                            <li key={index}>
                                <NavLink
                                    key={index} to={item.path} className={({ isActive }) => (isActive ? item.cName+' active' : 'inactive')}>
                                        <i className={item.icon}></i>&nbsp;<span>{item.title}</span>
                                </NavLink>
                            </li>
                        );
                    }else{
                        
                        return (

                            <>
                            <li className="dropdown" >
                                <Link
                                    to='#' className='dropdown-toggle' data-toggle="dropdown">
                                    <i className={item.icon}></i>&nbsp;<span>{item.title}</span>
                                </Link>
                                <ul className="dropdown-menu">
                                    {item.submenu.map((subitem, subindex) => {
                                        return (
                                            <li key={subindex}>
                                                <NavLink className={subitem.cName} to={subitem.path}>
                                                    {subitem.title}
                                                </NavLink>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </li>
                            </>
                        );
                    }
                }
            })}
        </ul>
      </> 
    );
}
export default PrimaryNavebar;