import React, { useState, useEffect }  from 'react'
import ButtonLoader from '../ButtonLoader';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import logoimg from '../../images/noimage.jpg';
import $ from 'jquery';
import * as utils from "../../Utils/comman";
function StudentForm(props) {
    const [isStudentSubmitting, setIsStudentSubmitting] = useState(false);
    const [errors, setErrors] = useState({});
    const [isEdit, setIsEdit] = useState(false);
    const [values, setValues] = useState({
        enrolmentNo: '',
        firstName: '',
        lastName: '',
        dob: (props.rowData) ? props.rowData.dob : '',
        addressLine: '',
        addressLine2: '',
        // class: '',
        // section: '',
        phoneNo: '',
        pinCode: '',
        city: '',
        state: '',
        studentProfilePic: ''
    });
    // const [classes, setClasses] = useState([]);
    // const [section, setSection] = useState([]);
    // const [isClassSelected, setIsClassSelected] = useState(false);
    // const [selectedClass, setSelectedClass] = useState('');
    
    const handleTextChange = e => {
        
        const { name, value } = e.target;
        
        setValues({
            ...values,
            [name]: value
        });
        // if(name == 'class'){
        //     //
        //     setSelectedClass(value);
        //     setIsClassSelected(true);
        //     //getSectionList(selectedClass);
        // }
        delete errors[name];
        
        
    };
    const handleStudentFormSubmit = (e) => {
       
        setErrors(validate(values));
        setIsStudentSubmitting(true);
       
        //props.onChange();
        e.preventDefault();
    }
    const [studentProfilePic, setStudentProfilePic] = useState('');
    const [tempStudentProfilePic, setTempStudentProfile] = useState(logoimg);
    const handleStudentFileChange = e => {
        setTempStudentProfile(URL.createObjectURL(e.target.files[0]));
        setStudentProfilePic(e.target.files[0]);
    }
    const handleStudentUpload = e => {
        var link = document.getElementById('studentProfilePic');
        link.click();
    }
    useEffect(() => {
        // getClassList();
        
        if(props.rowData && Object.keys(props.rowData).length > 0) {
            values['enrolmentNo'] = props.rowData.enrollmentNo;
            values['firstName'] = props.rowData.firstName;
            values['lastName'] = props.rowData.lastName;
            values['addressLine'] = props.rowData.addressLine1;
            values['addressLine2'] = props.rowData.addressLine2;
            values['phoneNo'] = props.rowData.phoneNo;
            values['pinCode'] = props.rowData.zipCode;
            // values['class'] = props.rowData.class;
            // values['section'] = props.rowData.schoolId;
            values['dob'] = props.rowData.dob;
            if (props.rowData.profilePic !== '') {
                setTempStudentProfile(props.rowData.profilePic_path);
            }
            setIsEdit(true)
        }
    }, []);
    
    // useEffect(() => {
    //     if(isEdit){
    //         getSectionList(props.rowData.class);
    //     }
    // }, [isEdit]);
    // useEffect(() => {
    //     if(isClassSelected){
    //         getSectionList(selectedClass);
    //     }
    // }, [isClassSelected]);
    useEffect(() => {
        if (isStudentSubmitting && Object.keys(errors).length === 0) {
            values.studentProfilePic = studentProfilePic;
           
            setIsStudentSubmitting(false);
            const propdata = {
                'values': values,
            }
            props.onChange(propdata);
        }else{
            setIsStudentSubmitting(false);
        }
    }, [isStudentSubmitting]);
    
    // function getClassList() {
    //     utils.callAPI('get', 'classes/list', '').then(
    //         res => {
    //             if (typeof res !== 'undefined') {
                    
    //                 if (Object.keys(res.result).length > 0) {
    //                     setClasses(
    //                         res.result.map(({ id, className }) => (
    //                             { label: className, value: id }
    //                         )
    //                     ));
    //                 }
    //             }
    //         }
    //     )
            
    // }
    // function getSectionList(selClass) {
    //     utils.callAPI('get', `section/list?classId=${selClass}`, '').then(
    //         res => {
    //             if (typeof res !== 'undefined') {
                    
    //                 if (Object.keys(res.result).length > 0) {
    //                     setSection(
    //                         res.result.map(({ id, section }) => (
    //                             { label: section, value: id }
    //                         )
    //                     ));
    //                     setSelectedClass('');
    //                     setIsClassSelected(false);
    //                 }else{
    //                     setSection([]);
    //                     setSelectedClass('');
    //                     setIsClassSelected(false);
    //                 }
    //             }
    //         }
    //     )
            
    // }
    return (
        <>
            <form onSubmit={handleStudentFormSubmit} className={(Object.keys(errors).length > 0) ? "needs-validation was-validated" : "needs-validation"} noValidate>
                <div className='row'>
                    <div className="col-md-3">
                        <div className="row justify-content-center">
                            <div className="col-md-12 mb-5">
                                <div className="profile-photo rounded mb-3">
                                    <img src={tempStudentProfilePic} className="img-fluid rounded" id="img_profile4" alt="profile" />
                                </div>
                                <a className="btn btn-block btn-outline-primary upload-button" id="btn-f-upload" onClick={handleStudentUpload}>Upload Photo</a>
                                <input id="studentProfilePic" type="file" className="form-control d-none" name="studentProfilePic" onChange={handleStudentFileChange} />
                            </div>
                        </div>
                    </div>
                    <div className='col-md-9'>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className="form-group">
                                    <label htmlFor="enrolmentNo">Enrolment No</label>
                                    <input id="enrolmentNo" type="text" className="form-control" name="enrolmentNo" value={values.enrolmentNo} onChange={handleTextChange} required/>
                                    {errors.enrolmentNo && <div className="invalid-feedback d-block">{errors.enrolmentNo}</div>}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="firstName">First Name</label>
                                    <input id="firstName" type="text" className="form-control" name="firstName" value={values.firstName} onChange={handleTextChange} required/>
                                    {errors.firstName && <div className="invalid-feedback d-block">{errors.firstName}</div>}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="lastName">Last Name</label>
                                    <input id="lastName" type="text" className="form-control" name="lastName" value={values.lastName} onChange={handleTextChange} required/>
                                    {errors.lastName && <div className="invalid-feedback d-block">{errors.lastName}</div>}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="dob">Date Of Birth</label>
                                    <DateRangePicker
                                        initialSettings={{
                                            singleDatePicker: true,
                                            showDropdowns: true,
                                            startDate: (values.dob === '') ? moment().format('DD-MM-YYYY') : moment(values.dob).format('DD-MM-YYYY'),
                                            minYear: 1901,
                                            maxYear: parseInt(moment().format('YYYY'), 10),
                                            locale: {
                                                format: 'DD-MM-YYYY'
                                            }
                                        }}

                                        onCallback={(start) => {
                                            values['dob'] = moment(start).format('YYYY-MM-DD')
                                        }}
                                    >
                                        <input type="text" name="dob" className="form-control"/>
                                    </DateRangePicker>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="addressLine">Address Line</label>
                                    <input id=" " type="text" className="form-control" name="addressLine" value={values.addressLine}  onChange={handleTextChange}/>
                                </div>
                                
                            </div>
                            <div className='col-md-6'>
                                {/* <div className="form-group">
                                    <label htmlFor="class">Class</label>
                                    
                                    <input id="class" type="text" className="form-control" name="class" value={values.class} onChange={handleTextChange}/>
                                    
                                    <select id="class" name="class" value={values.class} className="form-control" onChange={handleTextChange} required>
                                        <option value="">Select Class</option>
                                        {classes.reverse().map(({ label, value }) => (
                                            <option key={value} value={value}>
                                                {label}
                                            </option>
                                        ))}
                                        <option value="1">1st</option>
                                        <option value="2">2nd</option>
                                    </select>  
                                    {errors.class && <div className="invalid-feedback d-block">{errors.class}</div>}  
                                </div>
                                <div className="form-group">
                                    <label htmlFor="class">Section</label>
                                    
                                    <input id="class" type="text" className="form-control" name="class" value={values.class} onChange={handleTextChange}/>
                                    
                                    <select id="section" name="section" value={values.section} className="form-control" onChange={handleTextChange} required>
                                        <option value="">Select Section</option>
                                        {section.reverse().map(({ label, value }) => (
                                            <option key={value} value={value}>
                                                {label}
                                            </option>
                                        ))}
                                        <option value="1">1st</option>
                                        <option value="2">2nd</option>
                                    </select>  
                                    {errors.section && <div className="invalid-feedback d-block">{errors.section}</div>}  
                                </div> */}
                                <div className="form-group">
                                    <label htmlFor="phoneNo">Phone No</label>
                                    
                                    <input id="phoneNo" type="text" className={(errors.mobileNo && errors.mobileNo != '') ? "form-control is-invalid" : "form-control"} name="phoneNo" value={values.phoneNo} onChange={handleTextChange}/>
                                    {errors.mobileNo && <div className="invalid-feedback d-block">{errors.mobileNo}</div>}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="pinCode">Pin Code</label>
                                    <input id="pinCode" type="text" className="form-control" name="pinCode" value={values.pinCode}  onChange={handleTextChange}/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="state">State</label>
                                    {/* <input id="state" type="text" className="form-control" name="state" value={values.state} onChange={handleTextChange}/> */}
                                    <select id="state" name="state" className="form-control" onChange={handleTextChange} required>
                                        <option value="">Select State</option>
                                        <option value="1">Gujarat</option>
                                        <option value="2">Maharashtra</option>
                                    </select>    
                                </div>
                                <div className="form-group">
                                    <label htmlFor="city">City</label>
                                    {/* <input id="city" type="text" className="form-control" name="city" value={values.city} onChange={handleTextChange}/> */}
                                    <select id="city" name="city" className="form-control" onChange={handleTextChange} required>
                                        <option value="">Select State</option>
                                        <option value="1">Ahmedabad</option>
                                        <option value="2">Vadodara</option>
                                    </select>
                                </div>
                               <div className="form-group">
                                    <label htmlFor="addressLine2">Address Line2</label>
                                    <input id="addressLine2" type="text" className="form-control" name="addressLine2" value={values.addressLine2} onChange={handleTextChange}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className="form-group text-right">
                            <button type="submit" className="btn btn-primary btn-lg btn-icon icon-right d-flex align-items-center justify-content-center">
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}
function validate(values){
    
    let errors = {};
    var phoneno = /^\d{10}$/;
    if (!values.enrolmentNo) {
        errors.enrolmentNo = 'Please fill Enrolment number';
    }
    // else{
    //     delete errors['empNO']
    // }
    
    if (!values.firstName) {
        errors.firstName = 'Please fill first name';
    }
    // else{
    //     errors.firstName = '';
    // }
    if (!values.lastName) {
        errors.lastName = 'Please fill last name';
    }
    
    // if (!values.class) {
    //     errors.class = 'Please select class';
    // }
    
    // if (!values.section) {
    //     errors.section = 'Please select section';
    // }
    
    if (values.phoneNo !== '' && !values.phoneNo.match(phoneno)) {
        errors.mobileNo = 'Mobile No. is not valid';
    }
    return errors;
}
export default StudentForm