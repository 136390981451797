import React, {useState, useEffect} from 'react';
import Logo from "../Components/Logo";
import MobileNoForm from '../Components/Login/MobileNoForm';
import OtpForm from '../Components/Login/OtpForm';
import MainBg from "../Components/MainBg";
import * as utils from '../Utils/comman';
function Login() {
    const [isMobleSubmit, setMobleSubmit] = useState(false);
    const [mobileNo, setMobileNo] = useState('');
    function handleChange(newValue) {
        setMobileNo(newValue.mobileNo);
        setMobleSubmit(newValue.isMobleSubmit);
    }
    return (
        
        <section className="section">
            <div className="d-flex flex-wrap align-items-stretch">
                <div className="col-lg-4 col-md-6 col-12 order-lg-1 min-vh-100 order-2 bg-white d-flex align-items-center justify-content-center">
                <div className="p-4 m-3">
                    <Logo />
                    <h4 className="text-dark font-weight-normal">Login</h4>
                    {(!isMobleSubmit) ? <MobileNoForm isMobleSubmit={isMobleSubmit} mobileNo={mobileNo} onChange={handleChange} /> : <OtpForm mobileNo={mobileNo} />}
                    {/* <OtpForm /> */}
                    <div className="text-center mt-5 text-small">
                    Copyright &copy; Great Minds.
                    <div className="mt-2">
                        <a href="#">Privacy Policy</a>
                        <div className="bullet"></div>
                        <a href="#">Terms of Service</a>
                    </div>
                    </div>
                </div>
                </div>
                <div className="col-lg-8 col-12 order-lg-2 order-1 min-vh-100 background-walk-y position-relative overlay-gradient-bottom">

                <MainBg />
                <div className="absolute-bottom-left index-2">
                    <div className="text-light p-5 pb-2">
                    <div className="mb-5 pb-3">
                        <h1 className="mb-2 display-5 font-weight-bold">Welcome to Great Minds</h1>
                        {/* <h5 className="font-weight-normal text-muted-transparent">Bali, Indonesia</h5> */}
                    </div>

                    </div>
                </div>
                </div>
            </div>
        </section>
    );
}
export default Login