import React, { useState, useEffect } from "react";
import OTPInput, { ResendOTP } from "otp-input-react";
import * as utils from "../../Utils/comman";
import { useNavigate } from "react-router-dom";
import ButtonLoader from "../ButtonLoader";
import axios from "../../Utils/axios";
function OtpForm(props) {
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const obj = {
    width: "20%",
  };
  const otpResendButton = (buttonProps) => {
    return (
      <button className="btn btn-sm btn-outline-primary" {...buttonProps}>
        Resend
      </button>
    );
  };
  const handleResend = (e) => {
    //e.preventDefault();
    var bodyFormData = new FormData();
    bodyFormData.append("mobile_no", props.mobileNo);
    utils
      .callAPI("post", "authentication/mobile-no", bodyFormData)
      .then((res) => {});
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    //
    if (otp !== "") {
      setIsSubmitting(true);
    } else {
      utils.showMessage("Enter OTP", "error");
    }
  };
  useEffect(() => {
    if (isSubmitting) {
      var bodyFormData = new FormData();
      bodyFormData.append("otp", otp);
      bodyFormData.append("mobile_no", props.mobileNo);

      utils.callAPI("post", "authentication/otp", bodyFormData).then((res) => {
        setIsSubmitting(false);
        if (typeof res !== "undefined") {
          sessionStorage.setItem("auth_access_token", res.access_token);
          //localStorage.setItem("auth_access_token", res.access_token)
          
          setIsLogin(true);
          
        } else {
          setOtp("");
        }
      });
    }
  }, [isSubmitting]);
  useEffect(() => {
    if (isLogin === true) {
      //navigate("/dashboard");
      utils.callAPI("get", "users/profile").then((res) => {
          let roles = res["roles"];
          if (!roleExists(roles, "school_admin")) {
            sessionStorage.removeItem("auth_access_token");
            utils.showMessage("Access denied", "error");
            navigate("/");
          }
          localStorage.setItem("userData", JSON.stringify(res));
          localStorage.setItem("currentSchool", res.schoolIds);
          utils.callAPI('get', 'school/profile', '').then(

            res1 => {
                if (typeof res1 !== 'undefined') {
                    if (Object.keys(res1).length > 0) {
                      localStorage.setItem("schoolData", JSON.stringify(res1));
                    }
                }
                navigate("/dashboard");
            }
            
          )
          
      });
      
      
    }
  }, [isLogin]);
  
  function roleExists(arr, value) {
    return arr.some(function (el) {
      return el.slug === value;
    });
  }
  return (
    <>
      <form onSubmit={handleSubmit} className="needs-validation" noValidate>
        <div className="form-group">
          <label htmlFor="email">OTP</label>
          {/* <input id="otp" type="text" className="form-control" name="otp" tabindex="1" required autofocus /> */}
          <OTPInput
            value={otp}
            onChange={setOtp}
            inputClassName="form-control"
            autoFocus
            OTPLength={4}
            otpType="number"
            className="d-flex justify-content-between otp-container"
            inputStyles={obj}
            disabled={false}
            secure
          />

          <div className="invalid-feedback">Please fill in your OTP</div>
        </div>
        <div className="form-group">
          <ResendOTP
            onResendClick={handleResend}
            renderButton={otpResendButton}
            maxTime={60}
          />
        </div>
        <div className="form-group text-right">
          <button
            type="submit"
            className="btn btn-primary btn-lg btn-icon icon-right d-flex align-items-center w-100 justify-content-center"
            disabled={isSubmitting}
          >
            {isSubmitting ? <ButtonLoader /> : null}{" "}
            <span className="ml-2">Submit</span>
          </button>
        </div>
      </form>
    </>
  );
}

export default OtpForm;
