import React, { useEffect, useMemo, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { cloneDeep } from "lodash";
import * as utils from "../../Utils/comman";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment, { isDate } from "moment";
import ButtonLoader from "../ButtonLoader";
import { useCallback } from "react";

function Add(props) {
  const [feesStructure, setfeesStructure] = useState({
    class_id: "",
    year: "",
    structure_type: "",
    amount: "",
    data: [],
  });
  const [isFirstRender, setIsFirstRender] = useState(false);
  const [error, setError] = useState({});
  const [termsCount, setTermsCount] = useState("0");
  const [newSum, setNewSum] = useState();
  const [isTotalSum, setIsTotalSum] = useState(false);
  const [show, setShow] = useState(false);
  const [classes, setClasses] = useState([]);
  const [modelError, setModelError] = useState("");
  const [currentIFees, setCurrentIFees] = useState();
  const [cIndex, setCIndex] = useState([]);
  const [isTypeChange, setIstypeChange] = useState(false);
  const [modelFields, setModelFields] = useState([
    {
      particulars: "",
      amount: "",
    },
  ]);
  const [discount, setDiscount] = useState({
    discount_type: "",
    discount_value: "",
  });
  const [displayType, setDisplayType] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [istermLength, setIsTermLength] = useState(false);
  const [termvalue, setTermvalue] = useState();
  const [editData, setEditData] = useState([]);
  const [isDate, setisdate] = useState(false);
  const [isDiscount, setIsDiscount] = useState(false);
  const [isDiscountSubmit, setIsDiscountSubmit] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isEditSubmit, setIsEditSubmit] = useState(false);
  const [isEditError, setIsEditError] = useState("");

  let newDate = new Date();
  let date = moment(newDate).format("DD-MM-YYYY");
  let currentYear = newDate.getFullYear();
  let nextYear = newDate.getFullYear() + 1;
  let nextToNextYear = newDate.getFullYear() + 2;

  const years = [
    {
      label: `${currentYear}-${nextYear}`,
      value: `${currentYear}-${nextYear}`,
    },
    {
      label: `${nextYear}-${nextToNextYear}`,
      value: `${nextYear}-${nextToNextYear}`,
    },
  ];
  const type = [
    { label: "Terms", value: "1" },
    { label: "Installment", value: "2" },
    { label: "Full Payment", value: "3" },
  ];
  const discountType = [
    { label: "Percantage", value: "0" },
    { label: "Fixed", value: "1" },
  ];

  const handleBackClick = () => {
    const propdata = {
      mode: "list",
    };
    props.onChange(propdata);
  };

  useEffect(() => {
    getClassList();
  }, []);

  function getClassList() {
    utils.callAPI("get", "/classes/list", "").then((res) => {
      if (typeof res !== "undefined") {
        if (Object.keys(res.result).length > 0) {
          setClasses(
            res.result.map(({ id, className }) => ({
              label: className,
              value: id,
            }))
          );
        }
      }
    });
  }
  const handleYear = (e) => {
    setfeesStructure({ ...feesStructure, year: e.target.value });
  };

  const handleClass = (e) => {
    setfeesStructure({ ...feesStructure, class_id: e.target.value });
  };

  const handleType = (e) => {
    if (e.target.value == 1) {
      setDisplayType("Terms");
    } else if (e.target.value == 2) {
      setDisplayType("Installment");
    } else if (e.target.value == 3) {
      setDisplayType("Full Payment");
    }
    setIsEditError("");

    const isFull = e.target.value === "3";
    if (isFull) {
      setIstypeChange(true);
      setTermsCount(1);
      const extData = Array.from({ length: 1 }, () => ({
        amount: "",
        due_date: "",
        errors: {
          amout: "",
          due_date: "",
        },
      })).map((el, i) => ({
        ...el,
        label_name: `Full Payment`,
      }));

      if (istermLength) {
        // ***Edit Logic*** \\
        // select full payment on edit
        if (e.target.value == 3) {
          setNewSum(0);
          setTermsCount(1);
          const extData = Array.from({ length: 1 }, () => ({
            amount: "",
            due_date: "",
            errors: {
              amout: "",
              due_date: "",
            },
          })).map((el, i) => ({
            ...el,
            label_name: `Full Payment`,
          }));
          setfeesStructure({
            ...feesStructure,
            data: extData,
            structure_type: e.target.value,
          });
          setIsFirstRender(true);
        } else {
          const error = {
            amount: "",
            due_date: "",
          };
          const eData = cloneDeep(editData);
          editData.map((editField, i) => {
            eData[i].errors = error;
          });
          setfeesStructure({
            ...feesStructure,
            data: eData,
            structure_type: "3",
          });
        }
      } else {
        setfeesStructure({
          ...feesStructure,
          data: extData,
          structure_type: e.target.value,
        });
        setIsFirstRender(true);
      }
    } else {
      setIstypeChange(true);
      if (istermLength) {
        let typeName = "";
        if (e.target.value == 1) {
          typeName = "Terms";
        } else if (e.target.value == 2) {
          typeName = "Installment";
        } else if (e.target.value == 3) {
          typeName = "Full Payment";
        }
        setisdate(true);
        setNewSum(0);
        setTermsCount(1);
        const extData = Array.from({ length: 1 }, () => ({
          amount: "",
          due_date: "",
          errors: {
            amout: "",
            due_date: "",
          },
        })).map((el, i) => ({
          ...el,
          label_name: `${typeName}${i + 1}`,
        }));
        setIstypeChange(true);
        setfeesStructure({
          ...feesStructure,
          structure_type: e.target.value,
          data: extData,
        });
      } else {
        setfeesStructure({
          ...feesStructure,
          structure_type: e.target.value,
        });
      }
    }
  };

  const handleValue = (e) => {
    setError("");
    // const { value } = e.target;
    let value = "";
    if (istermLength) {
      // ***Edit Logic*** \\
      value = termvalue;
    } else {
      value = e.target.value;
      setTermsCount(value);
    }

    // setIstermsSubmited(false);
    // setIstermsSubmited(true);
    if (feesStructure.structure_type) {
      const extData = Array.from({ length: value }, () => ({
        amount: "",
        due_date: "",
        errors: {
          amount: "",
          due_date: "",
        },
      })).map((el, i) => ({
        ...el,
        label_name: `${displayType}${i + 1}`,
      }));
      if (istermLength) {
        // ***Edit Logic for set data in feesStructure*** \\
        const error = {
          amount: "",
          due_date: "",
        };
        const eData = cloneDeep(editData);
        editData.map((editField, i) => {
          eData[i].errors = error;
        });
        setfeesStructure({ ...feesStructure, data: eData });
      } else {
        setfeesStructure({ ...feesStructure, data: extData });
      }
      if (value > 0) {
        setIsFirstRender(true);
      } else {
        setIsFirstRender(false);
      }
    } else {
      let error = {};
      if (!feesStructure.structure_type) {
        error.type = "please select type";
      }
      setError({ ...error, error: error });
      return error;
    }
  };

  const handleData = (e, i) => {
    const { name, value } = e.target;
    const feeStDtCl = [...feesStructure.data];
    const feeStCl = {
      ...feeStDtCl[i],
      [name]: value,
      errors: { ...feeStDtCl.errors, [name]: "" },
    };
    feeStDtCl[i] = feeStCl;
    setfeesStructure({ ...feesStructure, data: feeStDtCl });

    setIsTotalSum(true);
  };

  useEffect(() => {
    var total = 0;
    feesStructure.data.forEach((item) => {
      if (!item.is_delete) {
        total += Number(item.amount);
      }
    });
    setNewSum(total);
    setfeesStructure({ ...feesStructure, amount: total });
    setIsTotalSum(false);
  }, [isTotalSum, newSum]);

  const handleFeesStructure = () => {
    let isError = false;
    const eData = feesStructure.data.map((data) => {
      let error = {};
      const errors = {
        amount: "",
        due_date: "",
      };
      if (!data.amount) {
        isError = true;
        errors.amount = "please enter fees amount";
      }
      if (!data.due_date) {
        isError = true;
        errors.due_date = "please enter due date";
      }
      if (!feesStructure.year) {
        isError = true;
        error.year = "Please select year";
      }
      if (!feesStructure.class_id) {
        isError = true;
        error.class = "Please select class";
      } else {
        error = "";
        // isError = false;
      }
      setError(error);
      return {
        ...data,
        errors,
      };
    });
    setfeesStructure({ ...feesStructure, data: eData });
    console.log(isError);
    if (isError == false) {
      // setIsSubmitting(true);
      feesStructure.data.map((fields, id) => {
        delete fields.errors;
      });
      if (istermLength) {
        // ***Call Edit Api*** \\
        // debugger
        feesStructure.data.map((feesList, id) => {
          if (!feesList.is_delete) {
            feesStructure.data.map((fields, id) => {
              delete fields.errors;
            });
            utils
              .callAPI("post", "/payment/edit-fees-structure", feesStructure)
              .then((res) => {
                if (typeof res !== "undefined") {
                  const propdata = {
                    mode: "list",
                  };
                  props.onChange(propdata);
                }
              });
            console.log("with error obj delete", feesStructure);
            // setIsEditSubmit(true);
            // setIsEditError("");
            // setIsSubmitting(false);
          } else {
            if (termsCount == 0) {
              setIsEditError(`Please add ${displayType} `);
            }
            // setIsEditSubmit(false);
            // setIsSubmitting(false);
          }
        });
        // console.log('edit api call',feesStructure)
      } else {
        utils
          .callAPI("post", "/payment/save-fees", feesStructure)
          .then((res) => {
            setIsSubmitting(false);
            if (typeof res !== "undefined") {
              const propdata = {
                mode: "list",
              };
              props.onChange(propdata);
            }
          });
      }
    } else {
      if (termsCount == 0) {
        setIsEditError(`Please add ${displayType} `);
      }
    }
  };

  // useEffect(() => {
  //   if (isEditSubmit) {
  //     console.log("only then call api for edit", feesStructure);
  //     setIsEditSubmit(true)
  //     // utils
  //     //   .callAPI("post", "/payment/edit-fees-structure", feesStructure)
  //     //   .then((res) => {
  //     //     if (typeof res !== "undefined") {
  //     //       const propdata = {
  //     //         mode: "list",
  //     //       };
  //     //       props.onChange(propdata);
  //     //     }
  //     //   });
  //   }
  // }, [isEditSubmit]);

  const handleShow = () => {
    setShow(() => !show);
    // setModelFields([
    //   {
    //     particulars: "",
    //     amount: "",
    //   },
    // ]);
    setModelError("");
  };

  const handleFormChange = (event, index) => {
    var data = [...modelFields];
    data[index][event.target.name] = event.target.value;
    setModelFields(data);
  };

  const addFields = (e, index) => {
    var object = {
      particulars: "",
      amount: "",
    };
    setModelFields([...modelFields, object]);
  };

  const removeFields = (e, index) => {
    let data = [...modelFields];
    data.splice(index, 1);
    setModelFields(data);
  };

  const handleOpenModel = (e, index) => {
    setCIndex(index);
    feesStructure.data.map((field, id) => {
      if (id == index) {
        setCurrentIFees(Number(field.amount));
      }
    });
    const eData = feesStructure.data.map((data, id) => {
      const errors = {
        amount: "",
        // due_date: "",
      };
      if (id == index) {
        if (!data.amount) {
          errors.amount = "enter fees amount";
        }
        // if (!data.due_date) {
        //   errors.due_date = "enter due date";
        // }
        else {
          if (istermLength) {
            // ***Edit Logic for modelField*** \\
            if (isTypeChange) {
              if (typeof data.parti != "undefined") {
                console.log("first", data.parti);
                setModelFields(data.parti);
                setShow(() => !show);
              } else {
                setModelFields([
                  {
                    particulars: "",
                    amount: "",
                  },
                ]);
                setShow(() => !show);
              }
            } else {
              console.log(data.parti);
              if (typeof data.parti == "undefined") {
                setModelFields([
                  {
                    particulars: "",
                    amount: "",
                  },
                ]);
                setShow(() => !show);
              } else if (data.parti.length !== 0) {
                setModelFields(data.parti);
                setShow(() => !show);
              } else {
                setModelFields([
                  {
                    particulars: "",
                    amount: "",
                  },
                ]);
                setShow(() => !show);
              }
            }
          } else {
            if (typeof data.parti != "undefined") {
              console.log("first", data.parti);
              setModelFields(data.parti);
              setShow(() => !show);
            } else {
              setModelFields([
                {
                  particulars: "",
                  amount: "",
                },
              ]);
              setShow(() => !show);
            }
          }
        }
      }
      return {
        ...data,
        errors,
      };
    });
    setfeesStructure({ ...feesStructure, data: eData });
  };

  const handleModel = () => {
    let newValue = [];
    var newLabel = {};
    let sum = "";
    modelFields.map((field, id) => {
      newValue[id] = Number(field.amount);
      newLabel = field.particulars;
    });

    for (var i = 0; i < modelFields.length; i++) {
      sum = newValue.reduce(function (a, b) {
        return a + b;
      });
    }
    if (Object.keys(newLabel).length == 0) {
      setModelError("Please enter value");
    } else if (currentIFees < Number(sum)) {
      setModelError("Tearms is not equal to fees");
    } else if (currentIFees > Number(sum)) {
      setModelError("Tearms is not equal to fees");
    } else {
      const fData = cloneDeep(feesStructure);
      fData.data[cIndex].parti = modelFields;
      setfeesStructure(fData);
      setShow(() => !show);
      setModelError("");
    }
  };

  const handleDiscount = (e) => {
    const { name, value } = e.target;
    setDiscount({
      ...discount,
      [name]: value,
    });
  };

  const handleSubmitDiscount = () => {
    setError(validateDiscount(discount));
  };

  function validateDiscount(discount) {
    let error = {};
    if (!feesStructure.year) {
      // isError = true;
      error.year = "Please select year";
    }
    if (!feesStructure.class_id) {
      // isError = true;
      error.class = "Please select class";
    }
    if (!discount.discount_type) {
      error.discount_type = "please select discount type";
    }
    if (!discount.discount_value) {
      error.discount_value = "please Enter discount number";
    }

    if (
      isNaN(discount.discount_value) ||
      discount.discount_value < 0 ||
      discount.discount_value > 100
    ) {
      error.discount_value = "value is out of range";
    } else {
      setIsDiscount(true);
    }
    return error;
  }

  //  Discount API //
  useEffect(() => {
    if (isDiscount) {
      setIsDiscountSubmit(true);
      const bodyFormData = new FormData();
      bodyFormData.append(
        "fees_structure_id",
        Number(discount.fees_structure_id)
      );
      bodyFormData.append("discount_type", Number(discount.discount_type));
      bodyFormData.append("discount_value", Number(discount.discount_value));
      utils
        .callAPI("post", "/payment/fees-discount", bodyFormData)
        .then((res) => {
          if (typeof res !== "undefined") {
            setIsDiscountSubmit(false);
            // setDiscount({
            //   discount_type: "",
            //   discount_value: "",
            // });
          }
        });
    }
  }, [isDiscount]);

  // ***Edit logic (set value in state)*** \\
  useEffect(() => {
    if (props.rowData && Object.keys(props.rowData).length > 0) {
      utils
        .callAPI(
          "get",
          `/payment/get-fees-structure?structure_id=${props.rowData}`,
          ""
        )
        .then((res) => {
          if (typeof res !== "undefined") {
            setDiscount({ ...discount, fees_structure_id: res.id });
            setfeesStructure({ ...feesStructure, id: res.id });
            setfeesStructure({ ...feesStructure, school_id: res.school_id });
            const editId = res.id;
            const editSchoolId = res.school_id;
            const editYear = res.year;
            const editClassId = res.class_id;
            feesStructure["year"] = editYear;
            feesStructure["class_id"] = editClassId;
            feesStructure["id"] = editId;
            feesStructure["school_id"] = editSchoolId;
            // setfeesStructure({ ...feesStructure, year: editYear });
            // setfeesStructure({ ...feesStructure, class_id: editClassId });
            setfeesStructure({
              ...feesStructure,
              structure_type: res.structure_type,
            });
            const typeLength = res.data.length;
            setEditData(res.data);
            setTermvalue(typeLength);
            setTermsCount(typeLength);
            setIsTermLength(true);
            setNewSum(res.amount);
            if (res.structure_type == 1) {
              setDisplayType("Terms");
            } else if (res.structure_type == 2) {
              setDisplayType("Installment");
            } else if (res.structure_type == 3) {
              setDisplayType("Full Payment");
            }
          }
        });
      // set discount api call
      utils
        .callAPI(
          "get",
          `/payment/get-fees-discount?fees_id=${props.rowData}`,
          ""
        )
        .then((res) => {
          if (typeof res !== "undefined") {
            res.map((dData, id) => {
              const discountType = dData.discount_type;
              discount["discount_type"] = discountType;
              // setDiscount({...discount,discount_type:dData.discount_type})
              setDiscount({
                ...discount,
                discount_value: dData.discount_value,
              });
            });
            setIsDisabled(true);
          } else {
            setIsDisabled(false);
          }
        });
    }
  }, [props.rowData]);

  useEffect(() => {
    if (istermLength) {
      handleValue();
    }
  }, [istermLength]);

  // ***Edit logic (Add New term)*** \\
  const handleNewType = () => {
    setIsEditError("");
    const newIndex = feesStructure.data.length + 1;
    // const extData = Array.from({ length: 1 }, () => ({
    //   amount: "",
    //   due_date: "",
    //   errors: {
    //     amount: "",
    //     due_date: "",
    //   },
    // })).map((el, i) => ({
    //   ...el,
    //   label_name: `${displayType}${newIndex}`,
    // }));
    //
    // setfeesStructure({ ...feesStructure, data: extData });
    setTermsCount(termsCount + 1);
    const newfeesStructure = {
      amount: "",
      due_date: "",
      structure_id: props.rowData,
      errors: {
        amount: "",
        due_date: "",
      },
      label_name: `${displayType}${newIndex}`,
    };
    // feesStructure.data.push(extData[0]);
    // //   setfeesStructure({...fee})
    // const extFeeSt = cloneDeep(feesStructure)
    //
    // editData.push({ ...newfeesStructure });
    setEditData([...editData, newfeesStructure]);
    feesStructure.data.push({ ...newfeesStructure });
  };

  // ***Edit logic (hide and delete perticular term)*** \\
  const handleDelete = useCallback((index) => async () => {
    setTermsCount(termsCount - 1);
    setIsTotalSum(true);
    const delFeeIn = cloneDeep(feesStructure);
    delFeeIn.data[index].is_delete = "yes";
    setfeesStructure(delFeeIn);
  });

  return (
    <>
      <div className="section-body">
        <div className="card card-primary">
          <div className="card-header">
            <h4>
              {istermLength ? "Edit Fees Structure" : "Add Fees Structure"}
            </h4>
            <div className="card-header-action">
              <button className="btn btn-primary" onClick={handleBackClick}>
                Back
              </button>
            </div>
          </div>
          <div className="card-body">
            <div className="row align-items-center">
              <div className="col-md-3">
                <label htmlFor="class">Year</label>
                <select
                  id="class"
                  name="year"
                  className="form-control"
                  onChange={handleYear}
                  value={feesStructure.year}
                >
                  <option>Select Year</option>
                  {years.map(({ label, value }, id) => (
                    <option key={label} value={value}>
                      {label}
                    </option>
                  ))}
                </select>
                <div className="col-auto">
                  {error.year ? (
                    <span className="invalid-feedback d-block">
                      {error.year}
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="col-md-3">
                <label htmlFor="class">Class</label>
                <select
                  id="class"
                  name="class"
                  className="form-control"
                  onChange={handleClass}
                  value={feesStructure.class_id}
                >
                  <option>Select Class</option>
                  {classes.reverse().map(({ label, value }, id) => (
                    <option key={label} value={value}>
                      {label}
                    </option>
                  ))}
                </select>
                <div className="col-auto">
                  {error.class ? (
                    <span className="invalid-feedback d-block">
                      {error.class}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-md">
                <div className="row mb-3">
                  <div className="col-md-5">
                    <label htmlFor="class">Type</label>
                    <select
                      id="class"
                      name="class"
                      className="form-control"
                      onChange={handleType}
                      value={feesStructure.structure_type}
                    >
                      <option value="">Select Type</option>
                      {type.map(({ label, value }, id) => (
                        <option key={label} value={value}>
                          {label}
                        </option>
                      ))}
                    </select>
                    <div className="col-auto">
                      {error.type ? (
                        <span className="invalid-feedback d-block">
                          {error.type}
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-5">
                    <label htmlFor="class">{`No. of ${displayType}`}</label>
                    <input
                      id="totalTerm"
                      type="number"
                      className="form-control"
                      value={termsCount}
                      onChange={handleValue}
                      placeholder={`${displayType}`}
                      disabled={displayType == "Full Payment" || istermLength}
                    />
                  </div>
                </div>
                {isFirstRender ? (
                  <div>
                    <div className="row align-items-center">
                      <div className="col-sm-1 mt-1 offset"></div>

                      <div className="col-sm-3 text-center">
                        <label htmlFor="class">Amount</label>
                      </div>
                      <div className="col-sm-3 text-center">
                        <label htmlFor="class">Due Date</label>
                      </div>
                    </div>
                  </div>
                ) : null}

                {feesStructure.data.map((fields, index) => (
                  <div key={index}>
                    {fields.is_delete ? null : (
                      <>
                        <div className="row" key={index}>
                          <div className="col-auto font-weight-bold mt-2">
                            <div className="form-group">
                              {displayType == "Full Payment" ? (
                                <label>{`${displayType}`}</label>
                              ) : (
                                <label>{`${displayType} ${index + 1}`}</label>
                              )}
                            </div>
                          </div>
                          <div className="col-sm-3">
                            <div className="form-group">
                              <input
                                id="amountEdit"
                                type="text"
                                name="amount"
                                className="form-control"
                                value={fields.amount}
                                onChange={(e) => handleData(e, index)}
                              />
                              {fields.errors.amount ? (
                                <span className="invalid-feedback d-block">
                                  {fields.errors.amount}
                                </span>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-sm-3">
                            <div className="form-group">
                              <DateRangePicker
                                initialSettings={{
                                  singleDatePicker: true,
                                  showDropdowns: true,
                                  startDate: istermLength
                                    ? !fields.due_date
                                      ? moment().format("DD-MM-YYYY")
                                      : moment(fields.due_date).format(
                                          "DD-MM-YYYY"
                                        )
                                    : moment().format("DD-MM-YYYY"),
                                  minDate: date,
                                  minYear: feesStructure.year.split("-")[0],
                                  maxYear: Number(
                                    feesStructure.year.split("-")[1]
                                  ),
                                  locale: {
                                    format: "DD-MM-YYYY",
                                  },
                                }}
                                name="due_date"
                                onCallback={(start) => {
                                  const selectedDate =
                                    moment(start).format("DD-MM-YYYY");
                                  // handleDueDate(selectedDate, index);
                                  // (e) => {handleData(e, index)}
                                  handleData(
                                    {
                                      target: {
                                        name: "due_date",
                                        value: selectedDate,
                                      },
                                    },
                                    index
                                  );
                                }}
                              >
                                <input
                                  type="text"
                                  name="due_date"
                                  placeholder="Due Date"
                                  className="form-control"
                                />
                              </DateRangePicker>
                              {fields.errors.due_date ? (
                                <span className="invalid-feedback d-block">
                                  {fields.errors.due_date}
                                </span>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-auto">
                            <div className="form-group">
                              <button
                                type="button"
                                className="btn btn-outline-primary"
                                onClick={(e) => {
                                  handleOpenModel(e, index);
                                }}
                              >
                                Add Particular
                              </button>
                              {!istermLength ||
                              displayType == "Full Payment" ? null : (
                                <button
                                  type="button"
                                  className="btn btn-outline-danger ml-2"
                                  // className="btn btn-sm btn-outline-danger"
                                  onClick={handleDelete(index)}
                                >
                                  <i className="fas fa-trash-alt"></i>
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                ))}
                {isFirstRender ? (
                  <>
                    <div className="row align-items-center">
                      <div className="col-auto font-weight-bold mt-2">
                        <label>Total</label>
                      </div>
                      <div className="col-sm-3 mt-2 font-weight-bold text-center">
                        <h6>{newSum}</h6>
                      </div>
                      <div className="col-sm-3 mt-2 offset"></div>
                      <div className="col-sm-3 text-center">
                        <div className="form-group">
                          {!istermLength ||
                          displayType == "Full Payment" ? null : (
                            <button
                              type="button"
                              className="btn btn-outline-primary"
                              onClick={(e) => {
                                handleNewType();
                              }}
                            >
                              {`Add ${displayType}`}
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="text-danger">{isEditError}</div>
                    <div className="row mb-3">
                      <div className="col-sm-2 font-weight-bold mt-2">
                        <button
                          type="submit"
                          className="btn btn-primary btn-lg btn-icon icon-right d-flex align-items-center justify-content-center"
                          onClick={handleFeesStructure}
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? <ButtonLoader /> : null}
                          <span className={isSubmitting ? "ml-2" : ""}>
                            Submit
                          </span>
                        </button>
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
              {istermLength ? (
                <>
                  <div className="col-md-4">
                    <h6>Set Discount for the full payment</h6>
                    <div className="form-group">
                      <select
                        id="discount"
                        name="discount_type"
                        className="form-control"
                        onChange={handleDiscount}
                        value={discount.discount_type}
                      >
                        <option value="">Select</option>
                        {discountType.map(({ label, value }, id) => (
                          <option key={label} value={value}>
                            {label}
                          </option>
                        ))}
                      </select>
                      {error.discount_type ? (
                        <span className="invalid-feedback d-block">
                          {error.discount_type}
                        </span>
                      ) : null}
                    </div>
                    <div className="row align-items-center">
                      <div className="col-md-5">
                        <input
                          type="number"
                          className="form-control"
                          name="discount_value"
                          onChange={handleDiscount}
                          value={discount.discount_value}
                        />
                      </div>
                      {discount.discount_type == 0 ? <span>%</span> : null}
                    </div>
                    {error.discount_value ? (
                      <span className="invalid-feedback d-block">
                        {error.discount_value}
                      </span>
                    ) : null}
                    <div className="row">
                      <div className="col-sm-2 font-weight-bold mt-2">
                        <button
                          type="submit"
                          className="btn btn-primary btn-lg btn-icon icon-right d-flex align-items-center justify-content-center"
                          disabled={isDisabled}
                          onClick={handleSubmitDiscount}
                        >
                          {isDiscountSubmit ? (
                            <ButtonLoader />
                          ) : (
                            <span>Submit</span>
                          )}
                        </button>
                        {/* <button
                          type="submit"
                          className="btn btn-primary"
                          onClick={handleSubmitDiscount}
                        >
                          SaveD
                        </button> */}
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleShow}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        // onShow={handleModelValue}
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Particulars
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row align-items-center ">
            <div className="col-md-4 text-center">
              <label htmlFor="particulars">Particulars</label>
            </div>
            <div className="col-md-4 text-center">
              <label htmlFor="particulars">Amount</label>
            </div>
          </div>
          {modelFields.map((modelField, index) => (
            <>
              <div className="row align-items-center mb-3">
                <div className="col-md-4">
                  <input
                    name="particulars"
                    onChange={(event) => handleFormChange(event, index)}
                    value={modelField.particulars}
                    className="form-control"
                  />
                </div>
                <div className="col-md-4">
                  <input
                    className="form-control"
                    name="amount"
                    onChange={(event) => handleFormChange(event, index)}
                    value={modelField.amount}
                  />
                </div>
                <div className="col-md-3">
                  {index !== modelFields.length - 1 ? (
                    <>
                      <button
                        className="btn btn-sm btn-outline-danger"
                        onClick={() => removeFields(index)}
                      >
                        Delete
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        type="button"
                        className="btn btn-outline-primary"
                        onClick={addFields}
                      >
                        Add New
                      </button>
                    </>
                  )}
                </div>
              </div>
            </>
          ))}
          {modelError ? (
            <div className="invalid-feedback d-block">{modelError}</div>
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleShow}>
            Close
          </Button>
          <Button variant="primary" onClick={handleModel}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Add;
