import axios from "axios";
import React from "react";
import { useCallback } from "react";
import { useMemo } from "react";
import { useEffect } from "react";
import { useState } from "react";
import DataTable from "react-data-table-component";
import swal from "sweetalert";
import * as utils from "../../Utils/comman";
import FilterComponent from "../FilterComponent";

function List(props) {
  const [subject, setSubject] = useState({});
  const [page, setPage] = useState(1);
  const countPerPage = 10;
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const columns = [
    {
      name: "Subjects",
      selector: (row) => row.subjectName,
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <button
            className="btn btn-sm btn-outline-primary"
            onClick={handelEdit(row)}
          >
            <i className="fas fa-edit"></i>
          </button>{" "}
          &nbsp;
          <button
            className="btn btn-sm btn-outline-danger"
            onClick={showDelete(row)}
          >
            <i className="fas fa-trash-alt"></i>
          </button>
        </>
      ),
    },
  ];

  const handleAddClick = (e) => {
    const propdata = {
      mode: "add",
    };
    props.onChange(propdata);
  };

  const tableData = {
    columns,
    subject,
  };
  useEffect(() => {
    getClassLists();
  }, [page, filterText]);

  const handelEdit = useCallback((row) => async () => {
    const propdata = {
      mode: "edit",
      rowData: row,
    };
    props.onChange(propdata);
  });

  const showDelete = useCallback((row) => async () => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      buttons: ["No", "Yes"],
      dangerMode: true,
    }).then((willDelete) => {
      
      if (willDelete) {
        utils.callAPI("post", `subject/delete?id=${row.id}`, "").then((res) => {
          if (typeof res !== "undefined") {
            getClassLists();
          }
        });
      }
    });
  });

  const getClassLists = () => {
    utils
      .callAPI(
        "get",
        `/subject/list?page=${page}&per_page=${countPerPage}&sear_text=${filterText}`,
        ""
      )
      .then((res) => {
        if (typeof res !== "undefined") {
          setSubject(res);
        } else {
          setSubject([]);
        }
      })
      .catch((err) => {
        setSubject([]);
      });
  };

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <>
      <div className="section-body">
        <div className="card card-primary">
          <div className="card-header">
            <h4>List</h4>
            <div className="card-header-action">
              <button className="btn btn-primary" onClick={handleAddClick}>
                Add Subject
              </button>
            </div>
          </div>
          <div className="card-body">
            <DataTable
              columns={columns}
              data={subject.result}
              highlightOnHover
              responsive
              pagination
              paginationServer
              paginationTotalRows={subject.total}
              paginationPerPage={countPerPage}
              paginationComponentOptions={{
                noRowsPerPage: true,
              }}
              onChangePage={(page) => setPage(page)}
              persistTableHead
              subHeader
              subHeaderComponent={subHeaderComponent}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default List;
