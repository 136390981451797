import React from 'react'
import smalllogoimg from '../images/great-minds-logo.png';
import { Link } from 'react-router-dom';
function LogoSmall () {
    return (
        <Link className="navbar-brand pl-md-3" to="/">
            <img src={smalllogoimg} className="img-fluid" alt="logo"/>
        </Link>
    )
}

export default LogoSmall