import React, { useEffect, useState } from "react";
import * as utils from "../../Utils/comman";
import ButtonLoader from "../ButtonLoader";

function Add(props) {
  const [values,setValues]=useState({
    id: "0",
    subjectName: "",
  })
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEdit,setisEdit]=useState(false)
  const [error,setError]=useState({})
  const [edit ,setEdit]=useState()

  const handleBackClick=(e)=>{
    const propdata={
      mode:'list'
    }
    props.onChange(propdata)
  }

  const handleFormSubmit=(e)=>{
    e.preventDefault()
    setError(validate(values));
    
    setIsSubmitting(true);
  }

    const handleTextChange = (e) => {
      const { name, value } = e.target;
      setValues({
        ...values,
        [name]: value,
      });
      delete error[name];
      // setErrors({
      //     ...errors,
      //     [name]: ''
      // });
    };

    useEffect(() => {
      if (isSubmitting && Object.keys(error).length === 0) {
        var bodyFormData = new FormData();
        bodyFormData.append("id", values.id);
        bodyFormData.append("subjectName", values.subjectName);
  
        utils.callAPI("post", "subject/save", bodyFormData).then((res) => {
          setIsSubmitting(false);
          if (typeof res !== "undefined") {
            const propdata = {
              mode: "list",
            };
            props.onChange(propdata);
          }
        });
      } else {
        setIsSubmitting(false);
      }
    }, [isSubmitting]);
  
    useEffect(() => {
      setisEdit(true);        
      if (props.rowData && Object.keys(props.rowData).length > 0) {

        //   
        values["id"] = props.rowData.id;
        values["subjectName"] = props.rowData.subjectName;
      }
    }, []);

  return (
    <>
      <div className="section body">
        <div className="card card-primary">
          <div className="card-header">
            <h4>{isEdit ? "Edit Subject":"Add Subject"}</h4>
            <div className="card-header-action">
              <button 
                className="btn btn-outline-primary"
                onClick={handleBackClick }>
                Back
              </button>
            </div>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-md-6 offset-md-3">
              <form
                className={
                  Object.keys(error).length > 0
                    ? "needs-validation was-validated"
                    : "needs-validation"
                }
                noValidate
                onSubmit={handleFormSubmit}
              >
                <div className="form-group">
                  <label htmlFor="mobileNo">Subject</label>
                  <input
                    id="subjectName"
                    type="text"
                    className="form-control"
                    name="subjectName"
                    value={values.subjectName}
                    onChange={handleTextChange}
                    required
                  />
                  {error.subjectName && (
                    <div className="invalid-feedback d-block">
                      {error.subjectName}
                    </div>
                  )}
                </div>

                <div className="form-group text-right">
                  <button
                    type="submit"
                    className="btn btn-primary btn-lg btn-icon icon-right d-flex align-items-center justify-content-center"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? <ButtonLoader /> : null}
                    <span className={isSubmitting ? "ml-2" : ""}>Submit</span>
                  </button>
                </div>
              </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
function validate(values) {
  let errors = {};
  if (!values.subjectName) {
    errors.empNo = "Please fill class";
  }
  return errors;
}

export default Add;
