import iziToast from "izitoast";
import axios from "../Utils/axios";

export const showMessage = (msg, type) => {
  switch (type) {
    case "success":
      iziToast.success({
        title: "Success",
        message: msg,
        position: "topRight",
      });
      break;
    case "error":
      iziToast.error({
        title: "Error",
        message: msg,
        position: "topRight",
      });
      break;
    default:
      iziToast.info({
        title: "Info",
        message: msg,
        position: "topRight",
      });
  }
};

// export const showDelete = (msg , type) => {
//     iziToast.error({
//             message: msg,
//             position: 'topRight',
//             progressBar: false,
//         });
// }

export const callAPI = (method, endpoint, data) => {
  let errors;
  axios.defaults.headers.common["Authorization"] =
    "Bearer " + sessionStorage.getItem("auth_access_token");
  if (method === "post") {
    return axios
      .post(endpoint, data)
      .then((res) => {
        if (res.data.status) {
          if (res.data.message !== "") {
            showMessage(`${res.data.message}`, "success");
          }
          return res.data.data;
        } else {
          errors = res.data.errors;
          Object.keys(errors).map(function (key, index) {
            showMessage(`${errors[key]}`, "error");
          });
        }
      })
      .catch((err) => {
        if (err.response.status === 400) {
          errors = err.response.data.errors;
          Object.keys(errors).map(function (key, index) {
            showMessage(`${errors[key]}`, "error");
          });
        } else if (err.response.status === 401) {
          showMessage("Access denied.", "error");
          window.location.href = "/";
        } else if (err.response.status === 404) {
          showMessage("API request not found", "error");
        } else {
          
          showMessage(err.message, "error");
          //window.location.href = '/';
        }
      });
  }
  if (method === "get") {
    return axios
      .get(endpoint)
      .then((res) => {
        if (res.data.status) {
          if (res.data.message !== "") {
            showMessage(`${res.data.message}`, "success");
          }
          return res.data.data;
        } else {
          errors = res.data.errors;
          Object.keys(errors).map(function (key, index) {
            showMessage(`${errors[key]}`, "error");
          });
        }
        //
      })
      .catch((err) => {
        if (err.response.status === 400) {
          errors = err.response.data.errors;
          Object.keys(errors).map(function (key, index) {
            showMessage(`${errors[key]}`, "error");
          });
        } else if (err.response.status === 401) {
          showMessage("Access denied.", "error");
          // window.location.href = "/";
        } else if (err.response.status === 404) {
          showMessage("API request not found", "error");
        } else {
          showMessage(err.message, "error");
          //window.location.href = "/";
        }
      });
  }
};
export const isAuthenticated = () => {
  let token = sessionStorage.getItem("auth_access_token");
  if (!token || token === "") {
    return false;
  } else {
    return true;
  }
};
export const getCurrentUser = () => {
  let userData = JSON.parse(localStorage.getItem("userData"));
  return userData;
};

export const getCurrentSchool = () => {
  let schoolData = JSON.parse(localStorage.getItem("schoolData"));
  return schoolData;
};
