import React, { useState } from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Sidebar from "../Components/Sidebar";
import List from "../Components/Sections/List";
import Add from "../Components/Sections/Add";
import ViewTeacher from "../Components/Sections/ViewTeacher";
import AddTeacher from "../Components/Sections/AddTeacher";
import ViewStudent from "../Components/Sections/ViewStudent";
import AddStudent from "../Components/Sections/AddStudent";
function Section() {
    const [comp, setComp] = useState("list");
    const [rowData, setRowData] = useState();
    function handleChange(newValue) {
        setComp(newValue.mode);
        setRowData(newValue.rowData);
    }
    const renderComp = () => {
        if (comp === 'list') {
            return (<List comp={comp} onChange={handleChange} rowData={rowData}/>);
        }else if(comp === "viewTeachers"){
            return (<ViewTeacher comp={comp} onChange={handleChange} rowData={rowData}/>);
        }else if(comp === "teacherAdd"){
            return (<AddTeacher comp={comp} onChange={handleChange} rowData={rowData}/>);
        }
        else if(comp === "viewStudent"){
            return (<ViewStudent comp={comp} onChange={handleChange} rowData={rowData}/>);
        }
        else if(comp === "studentAdd"){
            return (<AddStudent comp={comp} onChange={handleChange} rowData={rowData}/>);
        }
        else {
          return (<Add comp={comp} onChange={handleChange} rowData={rowData}/>);
        }
    }
    return (
        <>
            <Header />
            <Sidebar />
            <div className="main-content">
                <section className="section">
                    <div className="section-header">
                        <h1>Sections</h1>
                    </div>
                    {renderComp()}
                    
                </section>
            </div>
            <Footer />
        </>
    );
}
export default Section;