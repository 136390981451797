import React, { useState, useEffect, useCallback, useMemo } from "react";
import * as utils from "../../Utils/comman";
import DataTable from "react-data-table-component";
import swal from "sweetalert";
import FilterComponent from "../FilterComponent";
import { Button, Modal } from "react-bootstrap";

function List(props) {

  const [classes, setClasses] = useState({});
  const [page, setPage] = useState(1);
  const countPerPage = 10;
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [modalShow, setModalShow] = useState({
    show:false,
    classId:''
  });
  const [subject,setSubject]=useState()
  const [subjectId,setSubjectId] = useState([])
  const [error,setError]=useState('')
  // const [isSubmitting, setIsSubmitting] = useState(false);
  // const [isChecked,setIsChecked]=useState(false)
  // const [selectedSubject,setSelectedSubject]=useState([])
  const handleAddClick = (e) => {
    const propdata = {
      mode: "add",
    };
    props.onChange(propdata);
  };

  const columns = [
    {
      name: "Class",
      selector: (row) => row.className,
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <button
            className="btn btn-sm btn-outline-primary"
            onClick={handelEdit(row)}
          >
            <i className="fas fa-edit"></i>
          </button>{" "}
          &nbsp;
          <button
            className="btn btn-sm btn-outline-primary"
            onClick={handleShow(row)}
          >
            <i class="fas fa-check"></i>
          </button>
          &nbsp;
          <button
            className="btn btn-sm btn-outline-danger"
            onClick={showDelete(row)}
          >
            <i className="fas fa-trash-alt"></i>
          </button>
        </>
      ),
    },
  ];

  const tableData = {
    columns,
    classes,
  };

  useEffect(() => {
    getClassLists();
  }, [page, filterText]);

  useEffect(()=>{
    getSubjectLists()
  },[])

  const handelEdit = useCallback((row) => async () => {
    const propdata = {
      mode: "edit",
      rowData: row,
    };
    props.onChange(propdata);
  });

  const showDelete = useCallback((row) => async () => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      buttons: ["No", "Yes"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        utils.callAPI("post", `classes/delete?id=${row.id}`, "").then((res) => {
          if (typeof res !== "undefined") {
            getClassLists();
          }
        });
      }
    });
  });

  const getClassLists = () => {
    utils
      .callAPI(
        "get",
        `classes/list?page=${page}&perPage=${countPerPage}&search=${filterText}`,
        ""
      )
      .then((res) => {
        if (typeof res !== "undefined") {
          setClasses(res);
        } else {
          setClasses([]);
        }
      })
      .catch((err) => {
        setClasses([]);
      });
  };

  const getSubjectLists = () => {
    utils
      .callAPI(
        "get",
        `/subject/list?page=${page}&per_page=${countPerPage}&sear_text=${filterText}`,
        ""
      )
      .then((res) => {
        if (typeof res !== "undefined") {
          setSubject(res);
        } else {
          setSubject([]);
        }
      })
      .catch((err) => {
        setSubject([]);
      });
  };

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);
  
  const handleShow = useCallback((row) => async () => {
    const bodyFormData = new FormData();
    var newClassId = parseInt(row.id)
    bodyFormData.append("classId", newClassId);
    // setModalShow({show:true, classId:row.id})
    // utils.callAPI("post", 'subject/subjectList', bodyFormData)
    //   .then(
    //     res => {
    //       if (typeof res !== "undefined") {
    //         res.map((subId,id)=>{
    //           let newId=Number(subId.subjectId)
    //           setSubjectId(subjectId=>[...subjectId,newId])
    //           setModalShow({show:true,classId:row.id})
    //         })
    //       }
    //     }
    //     )
        utils.callAPI("post", "subject/subjectList", bodyFormData).then((res) => {
          if (typeof res !== "undefined") {
            res.map((subId,id)=>{
              setError('')
              let newId=Number(subId.subjectId)
              setSubjectId(subjectId=>[...subjectId,newId])
              setModalShow({show:true,classId:row.id})
            })
          }else{
            setModalShow({show:true.valueOf,classId:row.id})
          }
        });
  });

  const handleClose=()=>{
    setModalShow({show:false})
    setSubjectId([])
  }

  const addSubject=()=>{
    if(Object.keys(subjectId).length == 0){
      setError('Please select subject')
    }
    else{
    // var subId= subjectId.toLocaleString()
    var newClassId = parseInt(modalShow.classId)
    const bodyFormData = new FormData();
    bodyFormData.append("classId", newClassId);
    bodyFormData.append("subjectId", subjectId);
    utils.callAPI("post", '/subject/subjectMapping', bodyFormData)
      .then(
        res => {
          if (typeof res !== 'undefined') {
            setModalShow({show:false})
          }
        }
      )
    setSubjectId([])
    }
  }  

  return (
    <>
    <div className="section-body">
      <div className="card card-primary">
        <div className="card-header">
          <h4>List</h4>
          <div className="card-header-action">
            <button className="btn btn-primary" onClick={handleAddClick}>
              Add Class
            </button>
          </div>
        </div>
        <div className="card-body">
          <DataTable
            columns={columns}
            data={classes.result}
            highlightOnHover
            responsive
            pagination
            paginationServer
            paginationTotalRows={classes.total}
            paginationPerPage={countPerPage}
            paginationComponentOptions={{
              noRowsPerPage: true,
            }}
            onChangePage={(page) => setPage(page)}
            persistTableHead
            subHeader
            subHeaderComponent={subHeaderComponent}
          />
        </div>
      </div>
    </div>
    <Modal show={modalShow.show} onHide={handleShow} size='sm' centered>
      <Modal.Header >
        <Modal.Title>Select Subject</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      {
        subject?.result?.map((subName,index)=>
          {
            return(
              <> 
                <div className="form-check mb-2" key={index}>
                  <input 
                    className="form-check-input"
                    type="checkbox"
                    value={subName.id}
                    id={subName.id}
                    defaultChecked={
                      subjectId.includes(Number(subName.id))
                    }
                    onChange={(e)=>{
                      if(e.target.checked){
                        setError('')
                        let newId=subName.id
                        setSubjectId(current=>[...current,Number(newId)])
                      }
                      else{
                        let newId=subName.id
                        const newSelectedSub= subjectId.filter((subject) => subject !== Number(newId))
                        setSubjectId(newSelectedSub)
                      }
                    }}
                  />
                    <label className="form-check-label" htmlFor={subName.id}>
                      {subName.subjectName}
                    </label>
                </div>
              </>
            )
          })
      }
        <span className="text-danger">{error}</span>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="btn-close btn-close-white" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" className="ml-5" onClick={addSubject}>
          Add Subject
        </Button>
      </Modal.Footer>
    </Modal>
  </>
  );
}
export default List;
