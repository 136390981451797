import React, { useState, useEffect } from "react";
import * as utils from "../../Utils/comman";
import ButtonLoader from "../ButtonLoader";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import Select from "react-select";
function Add(props) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isValidate, setIsValidate] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [classes, setClasses] = useState([]);
  const [errors, setErrors] = useState({});
  const [values, setValues] = useState({
    id: 0,
    holidayName: "",
    startDate: "",
    endDate: "",
    class: "",
  });
  const [selectedOption, setSelectedOption] = useState(null);
  const [extraClasses, setExtraClasses] = useState([]);
  const handleBackClick = (e) => {
    const propdata = {
      mode: "list",
    };
    props.onChange(propdata);
  };
  const handleFormSubmit = (e) => {
    e.preventDefault();
    // setErrors(validate(values));
    setIsSubmitting(true);
  };
  const handleTextChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    delete errors[name];
  };
  useEffect(() => {
    if (isSubmitting && Object.keys(errors).length === 0) {
      var selClass = [];
      if (selectedOption != null) {
        // const allClasses = selectedOption.filter((opt) => opt.value !== "all");
        if (selectedOption.some((opt) => opt.value === "all")) {
          extraClasses
            .filter((opt) => opt.value !== "all")
            .forEach(function (element, i) {
              selClass.push(element.value);
            });
        } else {
          selectedOption.forEach(function (element, i) {
            selClass.push(element.label);
          });
        }
      }

      var bodyFormData = new FormData();
      bodyFormData.append("id", values.id);
      bodyFormData.append("holidayName", values.holidayName);
      bodyFormData.append("startDate", values.startDate);
      bodyFormData.append("endDate", values.endDate);
      if (selClass.length > 0) {
        bodyFormData.append("classId", selClass.join(","));
      }

      utils.callAPI("post", "holiday/save", bodyFormData).then((res) => {
        setIsSubmitting(false);
        if (typeof res !== "undefined") {
          const propdata = {
            mode: "list",
          };
          props.onChange(propdata);
        }
      });
    } else {
      setIsSubmitting(false);
    }
  }, [isSubmitting]);

  var selclass = [];
  useEffect(() => {
    getClassList();

    if (props.rowData && Object.keys(props.rowData).length > 0) {
      //
      values["id"] = props.rowData.id;
      values["holidayName"] = props.rowData.holidayName;
      values["startDate"] = props.rowData.startDate;
      values["endDate"] = props.rowData.endDate;
      const classarr = props.rowData.classes.split(",");

      const labelMap = props.rowData.label;
      for (var i = 0; i < classarr.length; i++) {
        const label = classarr[i];
        const value = labelMap[label];
        selclass.push({ label: label, value: value });
      }

      setIsEdit(true);
    }
  }, []);

  function getClassList() {
    utils.callAPI("get", "classes/list", "").then((res) => {
      if (typeof res !== "undefined") {
        if (Object.keys(res.result).length > 0) {
          const allClasses = [
            { label: "All", value: "all" },
            ...res.result.map(({ id, className }) => ({
              label: className,
              value: id,
            })),
          ];
          setExtraClasses(allClasses);
          // Filter out the classes that are already selected
          const availableClasses = allClasses.filter(
            (c) => !selclass.find((s) => s.label === c.label)
          );
          setClasses(availableClasses);
        }
      }
    });
  }

  const handleSelectChange = (selectedOption) => {
    if (
      Array.isArray(selectedOption) &&
      selectedOption.some((option) => option.value === "all")
    ) {
      // if((selectedOption.filter((option) => option.value !== "all").length > 0)){
      //   setSelectedOption( { label: "All", value: "all" })
      // }
      setSelectedOption(classes.filter((cls) => cls.value === "all"));
      setClasses(classes.filter((cls) => cls.value === "all"));
      selectedOption.splice(0, selectedOption.length, {
        label: "All",
        value: "all",
      });
      // setSelectedOption([classes.find((option) => option.value === "all")]);
      // setClasses([classes.find((option) => option.value === "all")]);
    } else {
      setClasses(extraClasses);
      const selectedLabels = selectedOption.map((option) => ({
        label: option.value,
      }));
      setSelectedOption(selectedLabels);
    }
  };

  return (
    <>
      <div className="section-body">
        <div className="card card-primary">
          <div className="card-header">
            <h4>{isEdit ? "Edit Holiday" : "Add Holiday"}</h4>
            <div className="card-header-action">
              <button
                className="btn btn-outline-primary"
                onClick={handleBackClick}
              >
                Back
              </button>
            </div>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-md-6 offset-md-3">
                <form
                  className={
                    Object.keys(errors).length > 0
                      ? "needs-validation was-validated"
                      : "needs-validation"
                  }
                  noValidate
                  onSubmit={handleFormSubmit}
                >
                  <div className="form-group">
                    <label htmlFor="mobileNo">Holiday Name</label>
                    <input
                      id="holidayName"
                      type="text"
                      className="form-control"
                      name="holidayName"
                      value={values.holidayName}
                      onChange={handleTextChange}
                      required
                    />
                    {errors.classText && (
                      <div className="invalid-feedback d-block">
                        {errors.holidayName}
                      </div>
                    )}
                  </div>
                  <div className="form-group">
                    <label htmlFor="mobileNo">Start Date</label>
                    <DateRangePicker
                      initialSettings={{
                        singleDatePicker: true,
                        showDropdowns: true,
                        startDate:
                          props.rowData != undefined
                            ? moment(props.rowData.startDate).format(
                                "DD-MM-YYYY"
                              )
                            : moment().format("DD-MM-YYYY"),
                        minYear: 1901,
                        maxYear: parseInt(moment().format("YYYY"), 10),
                        locale: {
                          format: "DD-MM-YYYY",
                        },
                      }}
                      onCallback={(start) => {
                        values["startDate"] =
                          moment(start).format("YYYY-MM-DD");
                      }}
                    >
                      <input
                        type="text"
                        name="startDate"
                        className="form-control"
                      />
                    </DateRangePicker>
                    {errors.startDate && (
                      <div className="invalid-feedback d-block">
                        {errors.startDate}
                      </div>
                    )}
                  </div>
                  <div className="form-group">
                    <label htmlFor="mobileNo">End Date</label>
                    <DateRangePicker
                      initialSettings={{
                        singleDatePicker: true,
                        showDropdowns: true,
                        startDate:
                          props.rowData != undefined
                            ? moment(props.rowData.endDate).format("DD-MM-YYYY")
                            : moment().format("DD-MM-YYYY"),
                        minYear: 1901,
                        maxYear: parseInt(moment().format("YYYY"), 10),
                        locale: {
                          format: "DD-MM-YYYY",
                        },
                      }}
                      onCallback={(start) => {
                        values["endDate"] = moment(start).format("YYYY-MM-DD");
                      }}
                    >
                      <input
                        type="text"
                        name="endDate"
                        className="form-control"
                      />
                    </DateRangePicker>
                    {errors.endDate && (
                      <div className="invalid-feedback d-block">
                        {errors.endDate}
                      </div>
                    )}
                  </div>
                  <div className="form-group">
                    <label htmlFor="class">Class</label>
                    <Select
                      defaultValue={selclass}
                      onChange={handleSelectChange}
                      options={classes}
                      isMulti
                      //defaultValue={[classes[0]]}
                    />
                    {errors.class && (
                      <div className="invalid-feedback d-block">
                        {errors.class}
                      </div>
                    )}
                  </div>
                  <div className="form-group text-right">
                    <button
                      type="submit"
                      className="btn btn-primary btn-lg btn-icon icon-right d-flex align-items-center justify-content-center"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? <ButtonLoader /> : null}
                      <span className={isSubmitting ? "ml-2" : ""}>Submit</span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
function validate(values) {
  let errors = {};
  if (!values.holidayName) {
    errors.holidayName = "Please fill holiday name";
  }
  if (!values.startDate) {
    errors.startDate = "Please select start date";
  }
  if (!values.endDate) {
    errors.endDate = "Please select end date";
  }
  // if (selectedOption.length == 0) {
  //   errors.class = "Please select Class";
  // }
  return errors;
}
export default Add;
