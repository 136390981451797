import React, { useState, useEffect } from 'react';
import Header from "../../Components/Header";
import Footer from '../../Components/Footer';
import Sidebar from '../../Components/Sidebar';
import * as utils from "../../Utils/comman";
import logoimg from '../../images/noimage.jpg';
import ButtonLoader from '../../Components/ButtonLoader';
import $ from 'jquery';
function SchoolProfile() {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [values, setValues] = useState({
        id: '',
        schoolName: '',
        address: '',
    });
    const [logofile, setFile] = useState('');
    const [tempLogofile, setTempFile] = useState(logoimg);
    const handleChange = e => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value
        });
    };
    const handleFileChange = e => {
        setTempFile(URL.createObjectURL(e.target.files[0]));
        setFile(e.target.files[0]);
    }
    const handleSubmit = (e) => {
        //setIsLoading(true);
        setIsSubmitting(true);
        e.preventDefault();
    }
    useEffect(() => {
        getData();
    }, []);
    useEffect(() => {
        if (isSubmitting) {

            var bodyFormData = new FormData();
            bodyFormData.append('schoolId', values.id);
            bodyFormData.append('schoolName', values.schoolName);
            bodyFormData.append('address', values.address);
            bodyFormData.append('logo', logofile);

            utils.callAPI('post', 'school/profile/save', bodyFormData).then(
                res => {
                    
                    //if (typeof res !== 'undefined') {
                        setIsSubmitting(false);
                    //}

                }
            )
        }
    }, [isSubmitting]);

    function getData() {
        utils.callAPI('get', 'school/profile', '').then(

            res => {
                if (typeof res !== 'undefined') {
                    if (Object.keys(res).length > 0) {
                        setValues({
                            id: res.id,
                            schoolName: res.schoolName,
                            address: res.address,
                        });
                        if (res.logo !== '') {
                            setTempFile(res.logo_path);
                        }
                    }
                }

            }
        )
    }
    return (
        <>
            <Header />
            <Sidebar />
            <div className="main-content">
                <section className="section">
                    <div className="section-header">
                        <h1>School Profile</h1>
                    </div>
                    <div className="section-body">
                        <div className="card card-primary">
                            
                            <div className="card-body">
                                <form onSubmit={handleSubmit} method="post" className="needs-validation" noValidate>
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="row justify-content-center">
                                            <div className="col-md-12 mb-5">
                                                <div className="profile-photo rounded mb-3">
                                                    <img src={tempLogofile} className="img-fluid rounded" id="img_profile" alt="profile" />
                                                </div>
                                                <a className="btn btn-block btn-outline-primary upload-button">Upload Logo</a>
                                                <input id="schoolLogo" type="file" className="form-control d-none" name="schoolLogo" onChange={handleFileChange} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-9">
                                        
                                            <div className="form-group">
                                                <label htmlFor="schoolName">School Name</label>
                                                <input id="schoolName" type="text" className="form-control" name="schoolName" onChange={handleChange} value={values.schoolName} required />
                                                <div className="invalid-feedback">
                                                    Please fill school name.
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="addresss">Address</label>
                                                <textarea id="address" className="form-control h-auto" name="address" value={values.address} rows="4" onChange={handleChange}></textarea>
                                                <div className="invalid-feedback">
                                                    Please fill address.
                                                </div>
                                            </div>
                                            {/* <div className="form-group">
                                            <label htmlFor="schoolLogo">Logo</label>
                                            <input id="schoolLogo" type="file" className="form-control" name="schoolLogo" onChange={handleFileChange} />
                                            <div className="invalid-feedback">
                                                Please select logo.
                                            </div>
                                        </div> */}
                                            <div className="form-group text-right">
                                                <button type="submit" className="btn btn-primary btn-lg btn-icon icon-right d-flex align-items-center justify-content-center" disabled={isSubmitting}>
                                                    {(isSubmitting) ? (<ButtonLoader />) : null}<span className={(isSubmitting) ? 'ml-2' : ''}>Submit</span>
                                                </button>
                                            </div>
                                        
                                    </div>
                                    
                                </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <Footer />
        </>
    );
}
$(document).ready(function () {
    $(".upload-button").on('click', function () {
        $("#schoolLogo").click();
    });
});
export default SchoolProfile