import React, { Fragment } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
  Switch,
} from "react-router-dom";
import Login from "./Pages/Login";
import { PrimaryMenu } from "./Components/PrimaryMenu";
import { ProtectedRoute } from "./Components/Protected.route";
import Dashboard from "./Pages/Dashboard";
function App() {
  return (
    <div className="App">
      <Fragment>
        <Routes>
          {PrimaryMenu.map((item, index) => {
            if (!("submenu" in item)) {
              return (
                <Route
                  key={index}
                  exact
                  path={item.path}
                  element={<ProtectedRoute />}
                >
                  <Route exact path={item.path} element={item.comp} />
                </Route>
              );
            } else {
              return (
                <Route
                  path={item.path}
                  key={index}
                  element={<ProtectedRoute />}
                >
                  <Route index={true} element={item.comp} key={index} />
                  {item.submenu.map((subitem, subindex) => {
                    return (
                      <Route
                        exact
                        path={subitem.path}
                        element={subitem.comp}
                        key={subindex}
                      />
                    );
                  })}
                </Route>
              );
            }
          })}
          ;
          <Route path="/" exact element={<Login />} key="login" />
        </Routes>
      </Fragment>
    </div>
  );
}

export default App;
