import React from 'react'

function ButtonLoader () {
    return (
        <div>
            <i className="fas fa-circle-notch fa-spin fa-2x"></i> 
        </div>
    )
}

export default ButtonLoader