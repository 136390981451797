import React, { useState, useEffect, useCallback, useMemo } from "react";
import * as utils from "../../Utils/comman";
import DataTable from "react-data-table-component";
import swal from "sweetalert";
import FilterComponent from "../FilterComponent";
function List(props) {
  const [holidays, setClasses] = useState({});
  const [page, setPage] = useState(1);
  const countPerPage = 10;
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const handleAddClick = (e) => {
    const propdata = {
      mode: "add",
    };
    props.onChange(propdata);
  };
  const columns = [
    {
      name: "Date",
      selector: (row) => row.startDate,
    },
    {
        name: "Day",
        selector: (row) => row.day,
    },
    {
        name: "Holiday",
        selector: (row) => row.holidayName,
    },
    {
        name: "Classes",
        selector: (row) => row.classes,
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <button
            className="btn btn-sm btn-outline-primary"
            onClick={handelEdit(row)}
          >
            <i className="fas fa-edit"></i>
          </button>{" "}
          &nbsp;
          <button
            className="btn btn-sm btn-outline-danger"
            onClick={showDelete(row)}
          >
            <i className="fas fa-trash-alt"></i>
          </button>
        </>
      ),
    },
  ];
  const tableData = {
    columns,
    holidays,
  };
  useEffect(() => {
    getHolidaysLists();
  }, [page, filterText]);

  const handelEdit = useCallback((row) => async () => {
    const propdata = {
      mode: "edit",
      rowData: row,
    };
    props.onChange(propdata);
  });

  const showDelete = useCallback((row) => async () => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      buttons: ["No", "Yes"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        utils.callAPI("post", `holiday/delete?id=${row.id}`, "").then((res) => {
          if (typeof res !== "undefined") {
            getHolidaysLists();
          }
        });
      }
    });
  });

  const getHolidaysLists = () => {
    utils
      .callAPI(
        "get",
        `holiday/list?page=${page}&perPage=${countPerPage}&search=${filterText}`,
        ""
      )
      .then((res) => {
        //
        if (typeof res !== "undefined") {
          setClasses(res);
        } else {
          setClasses([]);
        }
      })
      .catch((err) => {
        setClasses([]);
      });
  };

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);
  return (
    <>
      <div className="section-body">
        <div className="card card-primary">
          <div className="card-header">
            <h4>List</h4>
            <div className="card-header-action">
              <button className="btn btn-primary" onClick={handleAddClick}>
                Add Holiday
              </button>
            </div>
          </div>
          <div className="card-body">
            <DataTable
                columns={columns}
                data={holidays.result}
                highlightOnHover
                responsive
                pagination
                paginationServer
                paginationTotalRows={holidays.total}
                paginationPerPage={countPerPage}
                paginationComponentOptions={{
                noRowsPerPage: true,
                }}
                onChangePage={(page) => setPage(page)}
                persistTableHead
                subHeader
                subHeaderComponent={subHeaderComponent}
            />
          </div>
        </div>
      </div>
    </>
  );
}
export default List;
