import React, {useState, useEffect} from 'react';
import ButtonLoader from '../ButtonLoader';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import logoimg from '../../images/noimage.jpg';
import $ from 'jquery';

function GuardianForm(props) {
    const [isGuardianSubmitting, setIsGuardianSubmitting] = useState(false);
    const [errors, setErrors] = useState({});
    
    const [values, setValues] = useState({
        guardianFirstName: '',
        guardianLastName: '',
        guardianMobileNo: '',
        guardianEmail: '',
        guardianProfilePic: '',
        guardianProfileVisibility: false,
    });
    const handleTextChange = e => {
        const { name, value } = e.target;
        if(e.target.name != 'guardianProfileVisibility'){
            
            setValues({
                ...values,
                [name]: value
            });
        }else{
            if(e.target.checked){
                values['guardianProfileVisibility'] = true;
            }else{
                values['guardianProfileVisibility'] = false;
            }
        }
        delete errors[name];
        // setErrors({
        //     ...errors,
        //     [name]: ''
        // });
        
    };
    
    const [isStudentSubmitting, setIsStudentSubmitting] = useState(false);
    const handleGuardianFormSubmit = (e) => {
        setErrors(validate(values));
        setIsGuardianSubmitting(true);
        //props.onChange();
        e.preventDefault();
    }
    const [guardianProfilePic, setGuardianProfilePic] = useState('');
    const [tempGuardianProfilePic, setTempGuardianProfile] = useState(logoimg);
    const handleGuardianFileChange = e => {
        setTempGuardianProfile(URL.createObjectURL(e.target.files[0]));
        setGuardianProfilePic(e.target.files[0]);
    }
    const handleGuardianUpload = e => {
        var link = document.getElementById('guardianProfilePic');
        link.click();
    }
    
    useEffect(() => {
        if (isGuardianSubmitting && Object.keys(errors).length === 0) {
            values.guardianProfilePic = guardianProfilePic;
          
            setIsGuardianSubmitting(false);
            const propdata = {
                'values': values,
            }
            props.onChange(propdata);
        }else{
            setIsGuardianSubmitting(false);
        }
    }, [isGuardianSubmitting]);
    useEffect(() => {
        if(props.rowData && Object.keys(props.rowData).length > 0) {
            values['guardianFirstName'] = props.rowData.guardianFirstName;
            values['guardianLastName'] = props.rowData.guardianLastName;
            values['guardianMobileNo'] = props.rowData.guardianMobileNo;
            values['guardianEmail'] = props.rowData.guardianEmail;
            if (props.rowData.guardianProfilePic !== '') {
                setTempGuardianProfile(props.rowData.guardianProfilePic);
            }
            //setIsEdit(true)
        }
    }, []);
    return (
        <>
            <form onSubmit={handleGuardianFormSubmit} className={(Object.keys(errors).length > 0) ? "needs-validation was-validated" : "needs-validation"} noValidate>
                <div className='row'>
                    <div className="col-md-3">
                        <div className="row justify-content-center">
                            <div className="col-md-12 mb-5">
                                <div className="profile-photo rounded mb-3">
                                    <img src={tempGuardianProfilePic} className="img-fluid rounded" id="img_profile2" alt="profile" />
                                </div>
                                <a className="btn btn-block btn-outline-primary upload-button" id="btn-f-upload" onClick={handleGuardianUpload}>Upload Photo</a>
                                <input id="guardianProfilePic" type="file" className="form-control d-none" name="motherProfilePic" onChange={handleGuardianFileChange} />
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className="form-group">
                            <label htmlFor="guardianFirstName">First Name</label>
                            <input id="guardianFirstName" type="text" className="form-control" name="guardianFirstName" value={values.guardianFirstName} onChange={handleTextChange} required />
                            {errors.guardianFirstName && <div className="invalid-feedback d-block">{errors.guardianFirstName}</div>}
                        </div>
                        <div className="form-group">
                            <label htmlFor="guardianLastName">Last Name</label>
                            <input id="guardianLastName" type="text" className="form-control" name="guardianLastName" value={values.guardianLastName} onChange={handleTextChange} required />
                            {errors.guardianLastName && <div className="invalid-feedback d-block">{errors.guardianLastName}</div>}
                        </div>
                        <div className="form-group">
                            <label htmlFor="guardianMobileNo">Mobile No.</label>
                            <input id="guardianMobileNo" type="text" className="form-control" name="guardianMobileNo" value={values.guardianMobileNo} onChange={handleTextChange} required />
                            {errors.guardianMobileNo && <div className="invalid-feedback d-block">{errors.guardianMobileNo}</div>}
                        </div>
                        <div className="form-group">
                            <label htmlFor="guardianEmail">Email Add</label>
                            <input id="guardianEmail" type="email" className='form-control' name="guardianEmail" value={values.guardianEmail} onChange={handleTextChange} required/>
                            {errors.guardianEmail && <div className="invalid-feedback d-block">{errors.guardianEmail}</div>}
                        </div>
                        <div className="form-group">
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" name="guardianProfileVisibility" id="guardianProfileVisibility" onChange={handleTextChange}/>
                                <label className="form-check-label" htmlFor="guardianProfileVisibility">
                                Profile Visibility
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className="form-group text-right">
                            <button type="submit" className="btn btn-primary btn-lg btn-icon icon-right d-flex align-items-center justify-content-center">
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}
function validate(values){
    
    let errors = {};
    var phoneno = /^\d{10}$/;
    var email = /^\S+@\S+\.\S+$/;

    
    if (!values.guardianFirstName) {
        errors.motherFirstName = 'Please fill first name';
    }
    // else{
    //     errors.firstName = '';
    // }
    if (!values.guardianLastName) {
        errors.motherLastName = 'Please fill last name';
    }
    
    if (!values.guardianMobileNo) {
        errors.motherMobileNo = 'Please fill mobile number';
    }

    if (!values.guardianEmail) {
        errors.guardianEmail = 'Please fill email address';
    }
    
    if (values.guardianMobileNo !== '' && !values.guardianMobileNo.match(phoneno)) {
        errors.guardianMobileNo = 'Mobile No. is not valid';
    }

    if (values.guardianEmail !== '' && !values.guardianEmail.match(email)) {
        errors.guardianEmail = 'Email Add is not valid';
    }
    return errors;
}
export default GuardianForm