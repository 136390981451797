import React from 'react'
import loginbgimg from '../images/main-bg.png';
function loginBg () {
    return (
        <div className='login-main-bg'>
            <img src={loginbgimg} className="img-fluid" alt="Login Bg"/>
        </div>
    )
}

export default loginBg