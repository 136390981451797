import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from 'react-router-dom';
import $ from "jquery";

import 'bootstrap/dist/css/bootstrap.css';
import '@fortawesome/fontawesome-free/css/all.css';
import './stylescss/components.css';
import 'izitoast/dist/css/iziToast.min.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import './index.css';
import './stylescss/theme.css';

import 'bootstrap/dist/js/bootstrap.min.js';
import 'nicescroll/dist/jquery.nicescroll.min.js';
import 'sweetalert/dist/sweetalert.min.js';
import '@fortawesome/fontawesome-free/js/all.js';
import 'moment/moment.js'
import 'bootstrap-daterangepicker/daterangepicker.js';
//import 'izitoast/dist/js/iziToast.min.js';
import './stylescss/scripts.js';
//import 'react-select/dist/css/react-select.css';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  // <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  // </React.StrictMode>
);
$(document).ready(function() {

  //var headerHeight = $(".header").outerHeight();
  //$("body").css("padding-top", headerHeight);
  
});
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
