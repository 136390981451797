import React from "react";
import Logo from "./Logo";
import LogoSmall from "../Components/LogoSmall";
import PrimaryNavbar from "./PrimaryNavbar";
function Sidebar() {
  return (
    <>
      <div className="main-sidebar">
        <aside id="sidebar-wrapper">
          <div className="sidebar-brand">
          <LogoSmall />
          </div>
          <div className="sidebar-brand sidebar-brand-sm">
            <a href="index.html" className="text-primary">GM</a>
          </div>
          <PrimaryNavbar />
        </aside>
      </div>
    </>
  );
}
export default Sidebar;