import React, { useState, useEffect,useCallback, useMemo } from "react";
import * as utils from "../../Utils/comman";
import DataTable from 'react-data-table-component';
import swal from 'sweetalert';
import FilterComponent from "../FilterComponent";
function List(props) {
    const handleAddClick = (e) => {
        const propdata = {
          mode: "add",
        };
        props.onChange(propdata);
      };
      const [section, setSection] = useState({});
      const [page, setPage] = useState(1);
      const countPerPage = 10;
      const [filterText, setFilterText] = useState("");
      const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
      const columns = [
        {
            name: 'Class',
            selector: row => row.className,
        },
        {
            name: 'Section Name',
            selector: row => row.section,
        },
        
        {
            name: 'Action',
            cell: row => <>
                <button className='btn btn-sm btn-outline-primary' onClick={handelEdit(row)}><i className="fas fa-edit"></i></button> &nbsp;
                <button className='btn btn-sm btn-outline-primary' onClick={viewTeachers(row)}><i className="fas fa-users"></i></button> &nbsp;
                <button className='btn btn-sm btn-outline-primary' onClick={viewStudent(row)} ><i className="fas fa-user-graduate"></i></button> &nbsp;
                <button className='btn btn-sm btn-outline-danger' onClick={showDelete(row)}><i className="fas fa-trash-alt"></i></button> &nbsp;
                {(row.isActive == "1") ? 
                    <button className='btn btn-sm btn-outline-danger' onClick={handelDisable(row)}><i className="fas fa-lock-open"></i></button> : 
                    <button className='btn btn-sm btn-outline-danger' onClick={handelDisable(row)}><i className="fas fa-lock"></i></button>}
                
            </>
        },
    ];
    useEffect(() => {
        getSectionList();
    }, [page, filterText]);
    const handelEdit = useCallback(
        
        row => async () => {
            //
            const propdata = {
                'mode': 'edit',
                'rowData': row
            }
            props.onChange(propdata);
        
        },
    );
    const handelDisable = useCallback(
        
        row => async () => {
            
            let is_actvie = 1;
            if(row.isActive === '1'){
                is_actvie = 0;
            }
            utils.callAPI('get', `section/disable?sectionId=${row.id}&isActive=${is_actvie}`, '').then(
                res => {
                    if (typeof res !== 'undefined') {
                        getSectionList();
                    }
                }
            )
        
        },
    );
    const showDelete = useCallback(
        row => async () => {
            swal({
                title: 'Are you sure?',
                text: 'Once deleted, you will not be able to recover this imaginary file!',
                icon: 'warning',
                buttons: ["No", "Yes"],
                dangerMode: true,
            })
            .then((willDelete) => {
                if (willDelete) {
                    utils.callAPI('get', `section/delete?id=${row.id}`, '').then(
                        res => {
                            
                            if (typeof res !== 'undefined') {
                                getSectionList();
                            }
            
                        }
                    )
                } 
            });
        }
    )
    const viewTeachers = useCallback(
        row => async () => {
            //
            const propdata = {
                'mode': 'viewTeachers',
                'rowData': row
            }
            props.onChange(propdata);
        
        }
    )

    const viewStudent = useCallback(
        row => async () => {
            //
            const propdata = {
                'mode': 'viewStudent',
                'rowData': row
            }
            props.onChange(propdata);
        
        }
    )

    const getSectionList = () => {
        
        
        utils.callAPI('get', `section/list?page=${page}&per_page=${countPerPage}&sear_text=${filterText}`, '').then(
            res => {
                if (typeof res !== 'undefined') {
                    setSection(res);
                }else{
                    setSection({});        
                }

            }
        ).catch(err => {
            setSection({});
        });
      }

      const subHeaderComponent = useMemo(() => {
        
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText("");
            }
        };
        
        return (
            <FilterComponent
            onFilter={e => setFilterText(e.target.value)}
            onClear={handleClear}
            filterText={filterText}
            />
            
        );
    }, [filterText, resetPaginationToggle]);
    return (
        <>
            <div className="section-body">
                <div className="card card-primary">
                    <div className="card-header">
                        <h4>List</h4>
                        <div className="card-header-action">
                            <button className="btn btn-primary" onClick={handleAddClick}>
                                Add Section
                            </button>
                        </div>
                    </div>
                    <div className="card-body">
                        <DataTable
                            columns={columns}
                            data={section.result}
                            highlightOnHover
                            responsive
                            pagination
                            paginationServer
                            paginationTotalRows={section.total}
                            paginationPerPage={countPerPage}
                            paginationComponentOptions={{
                                noRowsPerPage: true
                            }}
                            onChangePage={page => setPage(page)}
                            persistTableHead
                            subHeader
                            subHeaderComponent={subHeaderComponent}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}
export default List;