import React, { useState, useEffect } from 'react';
import ButtonLoader from '../ButtonLoader';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import logoimg from '../../images/noimage.jpg';
import $ from 'jquery';

function FatherForm(props) {
    
    
    const [isFatherSubmitting, setIsFatherSubmitting] = useState(false);
    const [errors, setErrors] = useState({});
    
    const [values, setValues] = useState({
        fatherFirstName: '',
        fatherLastName: '',
        fatherMobileNo: '',
        fatherEmail:'',
        fatherProfilePic: '',
    });
    const handleTextChange = e => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value
        });
        delete errors[name];
        // setErrors({
        //     ...errors,
        //     [name]: ''
        // });
        
    };
    const handleFatherFormSubmit = (e) => {
        setErrors(validate(values));
        setIsFatherSubmitting(true);
        e.preventDefault();
    }
    const [fatherProfilePic, setFatherProfilePic] = useState('');
    const [tempfatherProfilePic, setTempFatherProfile] = useState(logoimg);
    const handleFatherFileChange = e => {
        setTempFatherProfile(URL.createObjectURL(e.target.files[0]));
        setFatherProfilePic(e.target.files[0]);
    }
    const handleFatherUpload = e => {
        var link = document.getElementById('fatherProfilePic');
        link.click();
    }
    useEffect(() => {
        if (isFatherSubmitting && Object.keys(errors).length === 0) {
            values.fatherProfilePic = fatherProfilePic;
           
            setIsFatherSubmitting(false);
            const propdata = {
                'values': values,
            }
            
            props.onChange(propdata);
           
        }else{
            setIsFatherSubmitting(false);
        }
    }, [isFatherSubmitting]);
    useEffect(() => {
        if(props.rowData && Object.keys(props.rowData).length > 0) {
            values['fatherFirstName'] = props.rowData.fatherFirstName;
            values['fatherLastName'] = props.rowData.fatherLastName;
            values['fatherMobileNo'] = props.rowData.fatherMobileNo;
            values['fatherEmail'] = props.rowData.fatherEmail;
            if (props.rowData.fatherProfilePic !== '') {
                setTempFatherProfile(props.rowData.fatherProfilePic);
            }
            //setIsEdit(true)
        }
    }, []);
    return (
        <>
            <form onSubmit={handleFatherFormSubmit} className={(Object.keys(errors).length > 0) ? "needs-validation was-validated" : "needs-validation"} noValidate>
                <div className='row'>
                    <div className="col-md-3">
                        <div className="row justify-content-center">
                            <div className="col-md-12 mb-5">
                                <div className="profile-photo rounded mb-3">
                                    <img src={tempfatherProfilePic} className="img-fluid rounded" id="img_profile" alt="profile" />
                                </div>
                                <a className="btn btn-block btn-outline-primary upload-button" id="btn-f-upload" onClick={handleFatherUpload}>Upload Photo</a>
                                <input id="fatherProfilePic" type="file" className="form-control d-none" name="fatherProfilePic" onChange={handleFatherFileChange} />
                                
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className="form-group">
                            <label htmlFor="fatherFirstName">First Name</label>
                            <input id="fatherFirstName" type="text" className="form-control" name="fatherFirstName" value={values.fatherFirstName} onChange={handleTextChange} required />
                            {errors.fatherFirstName && <div className="invalid-feedback d-block">{errors.fatherFirstName}</div>}
                        </div>
                        <div className="form-group">
                            <label htmlFor="fatherLastName">Last Name</label>
                            <input id="fatherLastName" type="text" className="form-control" name="fatherLastName" value={values.fatherLastName} onChange={handleTextChange} required />
                            {errors.fatherLastName && <div className="invalid-feedback d-block">{errors.fatherLastName}</div>}
                        </div>
                        <div className="form-group">
                            <label htmlFor="fatherMobileNo">Mobile No.</label>
                            <input id="fatherMobileNo" type="text" className={(errors.fatherMobileNo && errors.fatherMobileNo != '') ? "form-control is-invalid" : "form-control"} name="fatherMobileNo" value={values.fatherMobileNo} onChange={handleTextChange} required/>
                            {errors.fatherMobileNo && <div className="invalid-feedback d-block">{errors.fatherMobileNo}</div>}
                        </div>
                        <div className="form-group">
                            <label htmlFor="fatherEmail">Email Add</label>
                            <input id="fatherEmail" type="email" className='form-control' name="fatherEmail" value={values.fatherEmail} onChange={handleTextChange} required/>
                            {errors.fatherEmail && <div className="invalid-feedback d-block">{errors.fatherEmail}</div>}
                        </div>
                    </div>

                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className="form-group text-right">
                            <button type="submit" className="btn btn-primary btn-lg btn-icon icon-right d-flex align-items-center justify-content-center">
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}
function validate(values){
    
    let errors = {};
    var phoneno = /^\d{10}$/;
    var email = /^\S+@\S+\.\S+$/;
    
    if (!values.fatherFirstName) {
        errors.fatherFirstName = 'Please fill first name';
    }
    // else{
    //     errors.firstName = '';
    // }
    if (!values.fatherLastName) {
        errors.fatherLastName = 'Please fill last name';
    }
    
    if (!values.fatherMobileNo) {
        errors.fatherMobileNo = 'Please fill mobile number';
    }
    
    if (values.fatherMobileNo !== '' && !values.fatherMobileNo.match(phoneno)) {
        errors.fatherMobileNo = 'Mobile No. is not valid';
    }

    if (!values.fatherEmail) {
        errors.fatherEmail = 'Please fill email address';
    }

    if (values.fatherEmail !== '' && !values.fatherEmail.match(email)) {
        errors.fatherEmail = 'Email Add is not valid';
    }
    return errors;
}
export default FatherForm