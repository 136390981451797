import React, { useState, useEffect } from "react";
import * as utils from "../../Utils/comman";
import ButtonLoader from "../ButtonLoader";
function Add(props) {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isValidate, setIsValidate] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [errors, setErrors] = useState({});
    const [values, setValues] = useState({
        rowid: '0',
        class: "",
        section: "",
        classTeacher: "",
        classAdmin: "",
    });
    const [classes, setClasses] = useState([]);
    const [teacher, setTeachers] = useState([]);

    const handleBackClick = (e) => {
        const propdata = {
            mode: "list",
        };
        props.onChange(propdata);
    };
    const handleFormSubmit = (e) => {
        e.preventDefault();
        setErrors(validate(values));
        setIsSubmitting(true);
    };
    const handleTextChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
        delete errors[name];

    };
    useEffect(() => {
        
        if (isSubmitting && Object.keys(errors).length === 0) {
            
            var bodyFormData = new FormData();
            bodyFormData.append('id', values.rowid);
            bodyFormData.append('classId', values.class);
            bodyFormData.append('section', values.section);
            bodyFormData.append('classTeacherId', values.classTeacher);
            bodyFormData.append('classAdminId', values.classAdmin);
            
            utils.callAPI('post', 'section/save', bodyFormData).then(
                res => {
                    setIsSubmitting(false);
                    if (typeof res !== 'undefined') {
                        const propdata = {
                            'mode': 'list',
                        }
                        props.onChange(propdata);
                    }
                }
            )
        }else{
            setIsSubmitting(false);
        }
    }, [isSubmitting]);

    useEffect(() => {
        getClassList();
        getTeacherList();
        // 
        // 
        if(props.rowData && Object.keys(props.rowData).length > 0) {
            values['rowid'] = props.rowData.id;
            values['class'] = props.rowData.classId;
            values['section'] = props.rowData.section;
            values['classTeacher'] = props.rowData.classTeacher;
            values['classAdmin'] = props.rowData.classAdmin;
            setIsEdit(true)
        }
    }, []);
    
    function getClassList() {
        utils.callAPI('get', 'classes/list', '').then(
            res => {
                if (typeof res !== 'undefined') {

                    if (Object.keys(res.result).length > 0) {
                        setClasses(
                            res.result.map(({ id, className }) => (
                                { label: className, val: id }
                            )
                            ));
                    }
                }
            }
        )

    }
    function getTeacherList() {
        utils.callAPI('get', 'employee/list?role=2', '').then(
            res => {
               
                if (typeof res !== 'undefined') {

                    if (Object.keys(res.result).length > 0) {
                        setTeachers(
                            res.result.map(({ id, firstName, lastName }) => (
                                { label: firstName+" "+lastName, val: id }
                            )
                        ));
                    }
                }
            }
        )

    }
    return (
        <>
            <div className="section-body">
                <div className="card card-primary">
                    <div className="card-header">
                        <h4>{isEdit ? "Edit Section" : "Add Section"}</h4>
                        <div className="card-header-action">
                            <button
                                className="btn btn-outline-primary"
                                onClick={handleBackClick}
                            >
                                Back
                            </button>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-6 offset-md-3">
                                <form
                                    className={
                                        Object.keys(errors).length > 0
                                            ? "needs-validation was-validated"
                                            : "needs-validation"
                                    }
                                    noValidate
                                    onSubmit={handleFormSubmit}
                                >
                                    <div className="form-group">
                                        <label htmlFor="mobileNo">Class</label>
                                        <select id="class" name="class" value={values.class} className="form-control" onChange={handleTextChange} required>
                                            <option value="">Select Class</option>
                                            {classes.reverse().map(({ label, val }) => (
                                                <option key={val} value={val}>
                                                    {label}
                                                </option>
                                            ))}
                                            {/* <option value="1">1st</option>
                                        <option value="2">2nd</option> */}
                                        </select>
                                        {errors.class && <div className="invalid-feedback d-block">{errors.class}</div>}
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="section">Section</label>
                                        <input id="section" type="text" className="form-control" name="section" value={values.section} onChange={handleTextChange} required/>
                                        {errors.section && <div className="invalid-feedback d-block">{errors.section}</div>}  
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="mobileNo">Class Teacher</label>
                                        <select id="classTeacher" name="classTeacher" value={values.classTeacher} className="form-control" onChange={handleTextChange} required>
                                            <option value="">Select Class</option>
                                            {teacher.reverse().map(({ label, val }) => (
                                                <option key={val} value={val}>
                                                    {label}
                                                </option>
                                            ))}
                                            {/* <option value="1">1st</option>
                                        <option value="2">2nd</option> */}
                                        </select>
                                        {errors.classTeacher && <div className="invalid-feedback d-block">{errors.classTeacher}</div>}
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="mobileNo">Class Admin</label>
                                        <select id="classAdmin" name="classAdmin" value={values.classAdmin} className="form-control" onChange={handleTextChange} required>
                                            <option value="">Select Class</option>
                                            {teacher.reverse().map(({ label, val }) => (
                                                <option key={val} value={val}>
                                                    {label}
                                                </option>
                                            ))}
                                            {/* <option value="1">1st</option>
                                        <option value="2">2nd</option> */}
                                        </select>
                                        {errors.classAdmin && <div className="invalid-feedback d-block">{errors.classAdmin}</div>}
                                    </div>
                                    <div className="form-group text-right">
                                        <button type="submit" className="btn btn-primary btn-lg btn-icon icon-right d-flex align-items-center justify-content-center" disabled={isSubmitting}>
                                            {(isSubmitting) ? (<ButtonLoader />) : null}<span className={(isSubmitting) ? 'ml-2' : ''}>Submit</span>
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
function validate(values) {
    
    let errors = {};
    if (!values.class) {
        errors.class = "Please select class";
    }
    if (!values.section) {
        errors.section = "Please fill section";
    }
    if (!values.classTeacher) {
        errors.classTeacher = "Please select Class Teacher";
    }
    if(!values.classAdmin){
        errors.classAdmin = "Please select Class Admin";
    }
    return errors;
}
export default Add;