import React from "react";

function Footer() {
    let year = new Date().getFullYear();
  return (
      
    <>
      <footer className="main-footer">
        <div className="footer-left">
          Copyright &copy; {year} <div className="bullet"></div> 
          <a href="#">{process.env.REACT_APP_TITLE}</a>
        </div>
        
      </footer>
    </>
  );
}
export default Footer;
