import React, {useState, useEffect} from 'react';
import ButtonLoader from '../ButtonLoader';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import logoimg from '../../images/noimage.jpg';
import $ from 'jquery';

function MotherForm(props) {
    const [isMotherSubmitting, setIsMotherSubmitting] = useState(false);
    const [errors, setErrors] = useState({});
    
    const [values, setValues] = useState({
        motherFirstName: '',
        motherLastName: '',
        motherMobileNo: '',
        motherEmail:'',
        motherProfilePic: '',
        
    });
    const handleTextChange = e => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value
        });
        delete errors[name];
        // setErrors({
        //     ...errors,
        //     [name]: ''
        // });
        
    };
    const handleMotherFormSubmit = (e) => {
        setErrors(validate(values));
        setIsMotherSubmitting(true);
        e.preventDefault();
    }
    const [motherProfilePic, setMotherProfilePic] = useState('');
    const [tempMotherProfilePic, setTempMotherProfile] = useState(logoimg);
    const handleMotherFileChange = e => {
        setTempMotherProfile(URL.createObjectURL(e.target.files[0]));
        setMotherProfilePic(e.target.files[0]);
    }
    const handleMotherUpload = e => {
        var link = document.getElementById('motherProfilePic');
        link.click();
    }
    useEffect(() => {
        if (isMotherSubmitting && Object.keys(errors).length === 0) {
            values.motherProfilePic = motherProfilePic;
            
            setIsMotherSubmitting(false);
            const propdata = {
                'values': values,
            }
            
            props.onChange(propdata);
        }else{
            setIsMotherSubmitting(false);
        }
    }, [isMotherSubmitting]);
    useEffect(() => {
        if(props.rowData && Object.keys(props.rowData).length > 0) {
            values['motherFirstName'] = props.rowData.motherFirstName;
            values['motherLastName'] = props.rowData.motherLastName;
            values['motherMobileNo'] = props.rowData.motherMobileNo;
            values['motherEmail'] = props.rowData.motherEmail;

            if (props.rowData.motherProfilePic !== '') {
                setTempMotherProfile(props.rowData.motherProfilePic);
            }
            //setIsEdit(true)
        }
    }, []);
    return (
        <>
            <form onSubmit={handleMotherFormSubmit} className={(Object.keys(errors).length > 0) ? "needs-validation was-validated" : "needs-validation"} noValidate>
                <div className='row'>
                    <div className="col-md-3">
                        <div className="row justify-content-center">
                            <div className="col-md-12 mb-5">
                                <div className="profile-photo rounded mb-3">
                                    <img src={tempMotherProfilePic} className="img-fluid rounded" id="img_profile2" alt="profile" />
                                </div>
                                <a className="btn btn-block btn-outline-primary upload-button" id="btn-f-upload" onClick={handleMotherUpload}>Upload Photo</a>
                                <input id="motherProfilePic" type="file" className="form-control d-none" name="motherProfilePic" onChange={handleMotherFileChange} />
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className="form-group">
                            <label htmlFor="motherFirstName">First Name</label>
                            <input id="motherFirstName" type="text" className="form-control" name="motherFirstName" value={values.motherFirstName} onChange={handleTextChange} required />
                            {errors.motherFirstName && <div className="invalid-feedback d-block">{errors.motherFirstName}</div>}
                        </div>
                        <div className="form-group">
                            <label htmlFor="motherLastName">Last Name</label>
                            <input id="motherLastName" type="text" className="form-control" name="motherLastName" value={values.motherLastName} onChange={handleTextChange} required />
                            {errors.motherLastName && <div className="invalid-feedback d-block">{errors.motherLastName}</div>}
                        </div>
                        <div className="form-group">
                            <label htmlFor="motherMobileNo">Mobile No.</label>
                            <input id="motherMobileNo" type="text" className="form-control" name="motherMobileNo" value={values.motherMobileNo} onChange={handleTextChange} required />
                            {errors.motherMobileNo && <div className="invalid-feedback d-block">{errors.motherMobileNo}</div>}
                        </div>
                        <div className="form-group">
                            <label htmlFor="motherEmail">Email Add</label>
                            <input id="motherEmail" type="email" className='form-control' name="motherEmail" value={values.motherEmail} onChange={handleTextChange} required/>
                            {errors.motherEmail && <div className="invalid-feedback d-block">{errors.motherEmail}</div>}
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className="form-group text-right">
                            <button type="submit" className="btn btn-primary btn-lg btn-icon icon-right d-flex align-items-center justify-content-center">
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}
function validate(values){
    
    let errors = {};
    var phoneno = /^\d{10}$/;
    var email = /^\S+@\S+\.\S+$/;
    
    if (!values.motherFirstName) {
        errors.motherFirstName = 'Please fill first name';
    }
    // else{
    //     errors.firstName = '';
    // }
    if (!values.motherLastName) {
        errors.motherLastName = 'Please fill last name';
    }
    
    if (!values.motherMobileNo) {
        errors.motherMobileNo = 'Please fill mobile number';
    }
    
    if (!values.motherEmail) {
        errors.motherEmail = 'Please fill email address';
    }

    if (values.motherMobileNo !== '' && !values.motherMobileNo.match(phoneno)) {
        errors.motherMobileNo = 'Mobile No. is not valid';
    }

    if (values.motherEmail !== '' && !values.motherEmail.match(email)) {
        errors.motherEmail = 'Email Add is not valid';
    }
    return errors;
}
export default MotherForm
