import React, { useState, useEffect } from "react";
import * as utils from "../../Utils/comman";
import ButtonLoader from "../ButtonLoader";
function Add(props) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isValidate, setIsValidate] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isSameSchool, setIsSameSchool] = useState(false);

  const [values, setValues] = useState({
    rowId: "0",
    classText: "",
  });
  const [errors, setErrors] = useState({});
  const handleBackClick = (e) => {
    const propdata = {
      mode: "list",
    };
    props.onChange(propdata);
  };
  const handleTextChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    delete errors[name];
    // setErrors({
    //     ...errors,
    //     [name]: ''
    // });
  };
  const handleFormSubmit = (e) => {
    e.preventDefault();
    setErrors(validate(values));
    setIsSubmitting(true);
  };
  const handleValidate = (e) => {
    setIsValidate(true);
  };
  useEffect(() => {
    if (isSubmitting && Object.keys(errors).length === 0) {
      var bodyFormData = new FormData();
      bodyFormData.append("id", values.rowId);
      bodyFormData.append("className", values.classText);

      utils.callAPI("post", "classes/save", bodyFormData).then((res) => {
        setIsSubmitting(false);
        if (typeof res !== "undefined") {
          const propdata = {
            mode: "list",
          };
          props.onChange(propdata);
        }
      });
    } else {
      setIsSubmitting(false);
    }
  }, [isSubmitting]);

  useEffect(() => {
    if (props.rowData && Object.keys(props.rowData).length > 0) {
      values["rowId"] = props.rowData.id;
      values["classText"] = props.rowData.className;
      setIsEdit(true);
    }
  }, []);
  return (
    <div className="section-body">
      <div className="card card-primary">
        <div className="card-header">
          <h4>{isEdit ? "Edit Class" : "Add Class"}</h4>
          <div className="card-header-action">
            <button
              className="btn btn-outline-primary"
              onClick={handleBackClick}
            >
              Back
            </button>
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-6 offset-md-3">
              <form
                className={
                  Object.keys(errors).length > 0
                    ? "needs-validation was-validated"
                    : "needs-validation"
                }
                noValidate
                onSubmit={handleFormSubmit}
              >
                <div className="form-group">
                  <label htmlFor="mobileNo">Class</label>
                  <input
                    id="classText"
                    type="text"
                    className="form-control"
                    name="classText"
                    value={values.classText}
                    onChange={handleTextChange}
                    required
                  />
                  {errors.classText && (
                    <div className="invalid-feedback d-block">
                      {errors.classText}
                    </div>
                  )}
                </div>

                <div className="form-group text-right">
                  <button
                    type="submit"
                    className="btn btn-primary btn-lg btn-icon icon-right d-flex align-items-center justify-content-center"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? <ButtonLoader /> : null}
                    <span className={isSubmitting ? "ml-2" : ""}>Submit</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
function validate(values) {
  let errors = {};
  if (!values.classText) {
    errors.empNo = "Please fill class";
  }
  return errors;
}
export default Add;
