import React, { useState, useEffect, useCallback, useMemo } from "react";
import * as utils from "../../Utils/comman";
import ButtonLoader from "../ButtonLoader";
import DataTable from "react-data-table-component";
import swal from "sweetalert";
import FilterComponent from "../FilterComponent";
import { Button, Modal } from "react-bootstrap";
function List(props) {
  const [students, setUsers] = useState({});
  const [page, setPage] = useState(1);
  const countPerPage = 10;
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [modal, setModal] = useState(false);
  const [studentFile, setStudentFile] = useState("");
  const [isStudentFile, setIsStudentFile] = useState(false);
  const [error, setError] = useState("");
  const [show, setShow] = useState(false);

  const handleAddClick = (e) => {
    const propdata = {
      mode: "add",
    };
    props.onChange(propdata);
  };

  const columns = [
    {
      name: "Enrollment No",
      selector: (row) => row.enrollmentNo,
    },
    {
      name: "First Name",
      selector: (row) => row.firstName,
    },
    {
      name: "Last Name",
      selector: (row) => row.lastName,
    },
    {
      name: "Phone No",
      selector: (row) => row.phoneNo,
    },
    {
      name: "Status",
      selector: (row) => row.status,
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <button
            className="btn btn-sm btn-outline-primary"
            onClick={handelEdit(row)}
          >
            <i className="fas fa-edit"></i>
          </button>{" "}
          &nbsp;
          <button
            className="btn btn-sm btn-outline-danger"
            onClick={showDelete(row)}
          >
            <i className="fas fa-recycle"></i>
          </button>{" "}
          &nbsp;
          <button
            className="btn btn-sm btn-outline-primary"
            onClick={showStructure(row)}
          >
            <i className="fas fa-wallet"></i>
          </button>
        </>
      ),
    },
  ];

  const handelEdit = useCallback((row) => async () => {
    const propdata = {
      mode: "edit",
      rowData: row,
    };
    props.onChange(propdata);
  });

  const showDelete = useCallback((row) => async () => {
    swal({
      title: "Are you sure?",
      text: "Are you sure you want to change the status?",
      icon: "warning",
      buttons: ["No", "Yes"],
      dangerMode: true,
    }).then((willDelete) => {
      var statusId = "";
      if (row.status === "Active") {
        statusId = 0;
      } else {
        statusId = 1;
      }
      if (willDelete) {
        var bodyFormData = new FormData();
        bodyFormData.append("id", row.id);
        bodyFormData.append("status", Number(statusId));
        utils
          .callAPI("post", `students/change-status`, bodyFormData)
          .then((res) => {
            if (typeof res !== "undefined") {
              getStudentList();
            }
          });
      }
    });
  });

  const showStructure = useCallback((row) => async () => {
    const propdata = {
      mode: "fees-structure",
      rowData: row,
    };
    props.onChange(propdata);
  });

  const tableData = {
    columns,
    students,
  };

  useEffect(() => {
    getStudentList();
  }, [page, filterText]);
  const getStudentList = () => {
    utils
      .callAPI(
        "get",
        `students/school-student-list?page=${page}&per_page=${countPerPage}&search=${filterText}`,
        ""
      )
      .then((res) => {
        if (typeof res !== "undefined") {
          setUsers(res);
        } else {
          setUsers({});
        }
      })
      .catch((err) => {
        setUsers({});
      });
  };

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  //   const openModal=()=>{
  //     setModal((modal)=> !modal)
  //   }
  //   var input = document.createElement("input");
  //   input.setAttribute("required", "");
  //   input.type = "file";
  //   input.id = "studentfile";
  //   input.accept = ".xlsx, .xls, .csv";
  //   document.getElementsByTagName("studentfile").required = true;
  //   input.onchange = () => {
  //     uploadFile();
  //   };

  const fileSet = () => {
    const data = document.getElementById("studentfile").files[0];
    setStudentFile(data);
  };

  const uploadfile = () => {
    validateFileInput();
    if (studentFile) {
      setIsStudentFile(true);
    }
  };

  //   const bulkUpload = (e) => {
  //     swal({
  //       title: "Upload file",
  //       content: input,
  //       buttons: {
  //         cancel: true,
  //         confirm: true,
  //       },
  //     }).then((willupdate) => {
  //       if (willupdate) {
  //         setIsStudentFile(true)
  //       }
  //     });
  //   };

  useEffect(() => {
    if (isStudentFile) {
      const bodyFormData = new FormData();
      bodyFormData.append("student_data", studentFile);
      utils
        .callAPI("post", "/students/bulk-import", bodyFormData)
        .then((res) => {
          if (typeof res !== "undefined") {
            getStudentList();
            handleShow();
          }
        });
      setIsStudentFile(false);
    }
  }, [isStudentFile]);

  const validateFileInput = () => {
    setError(validate(studentFile));
  };

  function validate(value) {
    let error = {};
    if (value == "") {
      error.file = "Please select file";
    }

    return error;
  }

  const handleShow = () => setShow(() => !show);

  return (
    <>
      <div className="section-body">
        <div className="card card-primary">
          <div className="card-header">
            <h4>List</h4>
            <div className="card-header-action">
              <button className="btn btn-primary mr-2" onClick={handleAddClick}>
                Add Student
              </button>
              <button
                type="button"
                class="btn btn-primary"
                onClick={handleShow}
              >
                Bulk upload
              </button>
            </div>
          </div>
          <div className="card-body">
            {}
            <DataTable
              columns={columns}
              data={students.result}
              highlightOnHover
              responsive
              pagination
              paginationServer
              paginationTotalRows={students.total}
              paginationPerPage={countPerPage}
              paginationComponentOptions={{
                noRowsPerPage: true,
              }}
              onChangePage={(page) => setPage(page)}
              persistTableHead
              subHeader
              subHeaderComponent={subHeaderComponent}
            />
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleShow} size="md">
        <Modal.Header>
          <Modal.Title>File upload</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            type="file"
            accept=".xlsx, .xls, .csv"
            id="studentfile"
            name="file"
            onChange={fileSet}
          />
          {error.file && (
            <span className="invalid-feedback d-block">{error.file}</span>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleShow}>
            Close
          </Button>
          <Button variant="primary" onClick={uploadfile}>
            Upload
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default List;
