import React from "react";
import Dashboard from "../Pages/Dashboard";
import SchooProfile from "../Pages/Settings/SchoolProfile";
import Employees from "../Pages/Employees";
import Students from "../Pages/Students";
import Classes from "../Pages/Classes";
import Section from "../Pages/Section";
import Subjects from "../Pages/Subjects";
import Holidays from "../Pages/Holidays";
import Attendance from "../Pages/Attendance ";
import FeesStructure from "../Pages/FeesStructure";
export const PrimaryMenu = [
  {
    title: "Dashboard",
    icon: "fas fa-home",
    path: "/dashboard",
    cName: "nav-link",
    comp: <Dashboard />,
  },
  {
    title: "Employees",
    icon: "fas fa-user",
    path: "/employees",
    cName: "nav-link",
    comp: <Employees />,
  },
  {
    title: "Students",
    icon: "fas fa-user",
    path: "/students",
    cName: "nav-link",
    comp: <Students />,
  },
  {
    title: "Classes",
    icon: "fas fa-users",
    path: "/classes",
    cName: "nav-link",
    comp: <Classes />,
  },
  {
    title: "Sections",
    icon: "fas fa-users",
    path: "/section",
    cName: "nav-link",
    comp: <Section />,
  },
  {
    title: "Subjects",
    icon: "fas fa-book",
    path: "/subject",
    cName: "nav-link",
    comp: <Subjects />,
  },
  {
    title: "Holidays",
    icon: "fas fa-users",
    path: "/holidays",
    cName: "nav-link",
    comp: <Holidays />,
  },
  {
    title: "Attendance Report ",
    icon: "fas fa-users",
    path: "/attendance-report",
    cName: "nav-link",
    comp: <Attendance/>,
  },
  {
    title: "Fees Structure",
    icon: "fas fa-money-bill-wave",
    path: "/fees-structure",
    cName: "nav-link",
    comp: <FeesStructure/>,
  },
  {
    title: "Settings",
    icon: "fas fa-cogs",
    path: "/settings",
    cName: "nav-link has-dropdown",
    comp: "",
    submenu: [
      {
        title: "School Profile",
        path: "/settings/school-profile",
        cName: "nav-link",
        comp: <SchooProfile />,
      },
    ],
  },
];
