import React, { useState, useEffect } from "react";
import * as utils from "../../Utils/comman";
import ButtonLoader from "../ButtonLoader";
function MobileNoForm(props) {
  // function handleChange(event) {
  //     // Here, we invoke the callback with the new value
  //     props.onChange(true);
  // }
  const [isSubmitting, setIsSubmitting] = useState(false);
  //const [isLoading, setIsLoading] = useState(false);
  const [values, setValues] = useState({
    mobileNo: "",
  });
  const [errors, setErrors] = useState({});
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    //setIsLoading(true);
    setIsSubmitting(true);
  };
  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      var bodyFormData = new FormData();
      bodyFormData.append("mobile_no", values.mobileNo);
      bodyFormData.append("from", "web");

      utils
        .callAPI("post", "authentication/mobile-no", bodyFormData)
        .then((res) => {
          if (typeof res !== "undefined") {
            if (res.is_success) {
              //setIsLoading(false);
              const propdata = {
                isMobleSubmit: true,
                mobileNo: values.mobileNo,
              };
              props.onChange(propdata);
            } else {
              const propdata = {
                isMobleSubmit: false,
                mobileNo: "",
              };
              setIsSubmitting(false);
              props.onChange(propdata);
            }
          } else {
            const propdata = {
              isMobleSubmit: false,
              mobileNo: "",
            };
            setIsSubmitting(false);
            props.onChange(propdata);
          }
        });
    }
  }, [isSubmitting]);
  return (
    <div>
      <form onSubmit={handleSubmit} className="needs-validation" noValidate>
        <div className="form-group">
          <label htmlFor="mobileNo">Mobile No.</label>
          <input
            id="mobileNo"
            type="text"
            className="form-control"
            name="mobileNo"
            tabIndex="1"
            onChange={handleChange}
            required
          />
          <div className="invalid-feedback">Please fill in your Mobile No.</div>
        </div>
        <div className="form-group text-right">
          <button
            type="submit"
            className="btn btn-primary btn-lg btn-icon icon-right d-flex align-items-center w-100 justify-content-center"
            disabled={isSubmitting}
          >
            {isSubmitting ? <ButtonLoader /> : null}{" "}
            <span className="ml-2">Get OTP</span>
          </button>
        </div>
      </form>
    </div>
  );
}

export default MobileNoForm;
