import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import Select from "react-select";

import * as utils from '../../Utils/comman';
import ButtonLoader from '../ButtonLoader';

function AddStudent(props) {
    const [errors, setErrors] = useState({});
    const [page, setPage] = useState(1);
    const countPerPage = 10;
    const [values, setValues] = useState({
        rowid: '',
        student: {},
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [students, setStudents] = useState([]);
    // const [selectedOption, setSelectedOption] = useState(null);
    const [multiSelectedoption,setMultiSelectedOption]=useState([])

    const handleTextChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
        delete errors[name];

    };

    useEffect(() => {
      getStudentList();    
        }, [])

    

    const getStudentList = () => {
        utils.callAPI('get', `/section/students/list?sectionId=${props.rowData}`, '').then(
            res => {
                if (typeof res !== 'undefined') {
                    if (Object.keys(res).length > 0) {
                        
                        setStudents(
                            res.map(({ id, firstName, lastName }) => (
                                { label: firstName+" "+lastName, value: id }
                            )
                        )
                        );
                    }
                } 

            }
        )
    }

    useEffect(()=>{
        if(isSubmitting){
            const value=multiSelectedoption.join(',')
            
            
        if (isSubmitting && Object.keys(errors).length === 0) {
            var bodyFormData = new FormData();
            bodyFormData.append('sectionId', props.rowData);
            bodyFormData.append('studentId', value);
            utils.callAPI('post', 'section/students/add', bodyFormData).then(
                res => {
                    setIsSubmitting(false);
                    if (typeof res !== 'undefined') {
                        const propdata = {
                            'mode': 'viewStudent',
                            'rowData': {'id': props.rowData}
                        }
                        props.onChange(propdata);
                    }
                }
            )
        }else{
            setIsSubmitting(false);
        }
        }
    },[isSubmitting])
    
    const handleFormSubmit = (e) => {
        e.preventDefault();
        setErrors(validate(values));
        setIsSubmitting(true);
    };
    
function validate(values) {
    let errors = {};
    if (Object.keys(multiSelectedoption).length === 0) {
        errors.student = "Please select student";
    }
   
    return errors;
}
const handleBackClick = (e) => {
    const propdata = {
        mode: "viewStudent",
        'rowData': {'id': props.rowData}

    };
    props.onChange(propdata);
};

const handleMultiSelect = (e) =>{
    const value = e.map((stdId,id)=>Number(stdId.value))
    setMultiSelectedOption(value)
    // setMultiSelectedOption({...multiSelectedoption,value})
}



  return (
    <>
         <div className="section-body">
                <div className="card card-primary">
                    <div className="card-header">
                        <h4>{"Add Student"}</h4>
                        <div className="card-header-action">
                            <button
                                className="btn btn-outline-primary"
                                onClick={handleBackClick}
                            >
                                Back
                            </button>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-6 offset-md-3">
                                <form
                                    className={
                                        Object.keys(errors).length > 0
                                            ? "needs-validation was-validated"
                                            : "needs-validation"
                                    }
                                    noValidate
                                    onSubmit={handleFormSubmit}
                                >
                                <div className="form-group">
                                    <label htmlFor="class">Students</label>
                                    <Select
                                        onChange={handleMultiSelect}
                                        options={students}
                                        isMulti
                                    />
                                    {errors.student && (
                                        <div className="invalid-feedback d-block">
                                            {errors.student}
                                        </div>    
                                    )}
                                </div>
                                    <div className="form-group text-right">
                                        <button type="submit" className="btn btn-primary btn-lg btn-icon icon-right d-flex align-items-center justify-content-center" disabled={isSubmitting}>
                                            {(isSubmitting) ? (<ButtonLoader />) : null}<span className={(isSubmitting) ? 'ml-2' : ''}>Submit</span>
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </>
  )
}

export default AddStudent