import React, { useState } from 'react'
import Footer from '../Components/Footer';
import Header from '../Components/Header';
import Sidebar from '../Components/Sidebar';
import List from '../Components/Attendance/List'
function Attendance () {
    const [comp, setComp] = useState('list');
    const [rowData, setRowData] = useState();
    function handleChange(newValue) {
        setComp(newValue.mode);
        setRowData(newValue.rowData);
    }
    return (
        <>
            <Header />
            <Sidebar />
            <div className="main-content">
                <section className="section">
                    <div className="section-header">
                        <h1>Attendance Report</h1>
                    </div>
                    {(comp === 'list') ? <List comp={comp} onChange={handleChange} rowData={rowData}/> :  null
                    // <Add comp={comp} rowData={rowData} onChange={handleChange}/>
                    }
                </section>
            </div>
            <Footer />
        </>
    );
}

export default Attendance 