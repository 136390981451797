import React, { useState, useEffect,useCallback, useMemo } from 'react'
import * as utils from '../../Utils/comman';
import ButtonLoader from '../ButtonLoader';
import DataTable from 'react-data-table-component';
import swal from 'sweetalert';
import FilterComponent from "../FilterComponent";
import { Button, Modal } from 'react-bootstrap';
function List(props) {
    const handleAddClick = e => {
        const propdata = {
            'mode': 'add',
        }
        props.onChange(propdata);
    };
    const [emp, setUsers] = useState({});
    const [page, setPage] = useState(1);
    const countPerPage = 10;
    const [filterText, setFilterText] = useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [employeeFile, setEmployeeFile] = useState("");
    const [isStudentFile, setIsStudentFile] = useState(false);
    const [error,setError]=useState('')
    const [show, setShow] = useState(false);

    const columns = [
        {
            name: 'Employee No.',
            selector: row => row.empNo,
        },
        {
            name: 'First Name',
            selector: row => row.firstName,
        },
        {
            name: 'Last Name',
            selector: row => row.lastName,
        },
        {
            name: 'Designation',
            selector: row => row.designation,
        },
        {
            name: 'Mobile No.',
            selector: row => row.mobileNo,
        },
        {
            name: 'Action',
            cell: row => <>
            <button className='btn btn-sm btn-outline-primary' onClick={handelEdit(row)}><i className="fas fa-edit"></i></button> &nbsp;
            <button className='btn btn-sm btn-outline-danger' onClick={showDelete(row)}><i className="fas fa-trash-alt"></i></button>
            </>
        },
    ];
    const tableData = {
        columns,
        emp
      };
    useEffect(() => {
        getEmpList();
    }, [page, filterText]);
    
    const handelEdit = useCallback(
        row => async () => {
            
            const propdata = {
                'mode': 'edit',
                'rowData': row
            }
            props.onChange(propdata);
        
        },
      );
      
      const showDelete = useCallback(
        row => async () => {
            swal({
                title: 'Are you sure?',
                text: 'Once deleted, you will not be able to recover this imaginary file!',
                icon: 'warning',
                buttons: ["No", "Yes"],
                dangerMode: true,
              })
              .then((willDelete) => {
                if (willDelete) {
                    let currentSchool = localStorage.getItem('currentSchool');
                    utils.callAPI('get', `employee/delete?id=${row.id}&schoolId=${currentSchool}`, '').then(
                        res => {
                            
                            if (typeof res !== 'undefined') {
                                getEmpList();
                            }
            
                        }
                    )
                } 
              });
        }
      )
    const getEmpList = () => {
        
        utils.callAPI('get', `employee/list?page=${page}&per_page=${countPerPage}&sear_text=${filterText}`, '').then(
            res => {
                if (typeof res !== 'undefined') {
                    setUsers(res);
                }else{
                    setUsers({});        
                }
                
            }
        ).catch(err => {
            setUsers({});
        });
      }
    
   
    const subHeaderComponent = useMemo(() => {
        
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText("");
            }
        };
        
        return (
            <FilterComponent
            onFilter={e => setFilterText(e.target.value)}
            onClear={handleClear}
            filterText={filterText}
            />
            
        );
    }, [filterText, resetPaginationToggle]);
    
  const fileSet = () => {
    const data = document.getElementById("employeeFile").files[0];
    setEmployeeFile(data);
  };

    const uploadfile = () => {
        validateFileInput()
        if(employeeFile){
            setIsStudentFile(true)
        }
    }

//   const bulkUpload = (e) => {
//     swal({
//       title: "Upload file",
//       content: input,
//       buttons: {
//         cancel: true,
//         confirm: true,
//       },
//     }).then((willupdate) => {
//       if (willupdate) {
//         setIsStudentFile(true)
//       }
//     });
//   };

  useEffect(() => {
    if (isStudentFile) {
        const bodyFormData = new FormData();
        bodyFormData.append("employee_data", employeeFile);
        utils.callAPI("post",'/employee/bulk-import', bodyFormData)
        .then(
            res => {
              if (typeof res !== 'undefined') {
                getEmpList()
                handleShow()
              }
            }
          )
      setIsStudentFile(false);
    }
  }, [isStudentFile]);

  const validateFileInput=()=>{
    setError(validate(employeeFile))
  }

  function validate(value){
    let error = {};
    if(value == ''){
        error.file = 'Please select file';
    }

    return error
  }

    const handleShow = () => setShow(()=>!show);

    return (
        <>
        <div className="section-body">
            <div className="card card-primary">
                <div className="card-header">
                    <h4>List</h4>
                    <div className="card-header-action">
                        <button className="btn btn-primary mr-2" onClick={handleAddClick}>
                            Add Employee
                        </button>
                        <button type="button" class="btn btn-primary"onClick={handleShow}>
                            Bulk upload
                        </button>
                    </div>
                </div>
                <div className="card-body">
                
                    <DataTable
                        columns={columns}
                        data={emp.result}
                        highlightOnHover
                        responsive
                        pagination
                        paginationServer
                        paginationTotalRows={emp.total}
                        paginationPerPage={countPerPage}
                        paginationComponentOptions={{
                            noRowsPerPage: true
                        }}
                        onChangePage={page => setPage(page)}
                        persistTableHead
                        subHeader
                        subHeaderComponent={subHeaderComponent}
                    />
                    
                </div>
            </div>
        </div>
        <Modal show={show} onHide={handleShow} size='md'>
            <Modal.Header>
                <Modal.Title>File upload</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <input type='file' accept=".xlsx, .xls, .csv" id='employeeFile' name='file' onChange={fileSet}/>
                {error.file && <span className="invalid-feedback d-block">{error.file}</span>}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleShow}>
                    Close
                </Button>
                <Button variant="primary" onClick={uploadfile}>
                    Upload
                </Button>
            </Modal.Footer>
        </Modal>
        </>
    )
}
export default List