import React from 'react'
import logoimg from '../images/great-minds-logo.png';
import { Link } from 'react-router-dom';
function Logo () {
    return (
        <Link className="navbar-brand pl-md-3" to="/"> 
            <img src={logoimg} className="img-fluid" alt="logo"/> 
        </Link>
    )
}

export default Logo