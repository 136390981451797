import React, { useState, useEffect } from 'react'
import * as utils from '../../Utils/comman';
import ButtonLoader from '../ButtonLoader';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import logoimg from '../../images/noimage.jpg';
import $, { error } from 'jquery';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import StudentForm from './StudentForm';
import FatherForm from './FatherForm';
import MotherForm from './MotherForm';
import GuardianForm from './GuardianForm';
function Add(props) {
    const handleBackClick = (e) => {
        const propdata = {
            'mode': 'list',
        }
        props.onChange(propdata);
    }
    const [values, setValues] = useState({
        id: (props.rowData ? props.rowData.id : 0),
        enrolmentNo: '',
        firstName: '',
        lastName: '',
        dob: '',
        addressLine: '',
        addressLine2: '',
        // class: '',
        phoneNo: '',
        pinCode: '',
        city: '',
        state: '',
        fatherFirstName: '',
        fatherLastName: '',
        fatherMobileNo: '',
        fatherEmail:' ',
        motherFirstName: '',
        motherLastName: '',
        motherMobileNo: '',
        motherEmail:'',
        guardianFirstName: '',
        guardianLastName: '',
        guardianMobileNo: '',
        guardianEmail:'',
        guardianProfileVisibility: false,
        studentProfilePic: '',
        fatherProfilePic: '',
        motherProfilePic: '',
    });
    const [isFathertabDisabled, setIsFatherTabDisabled] = useState(true);
    const [isMothertabDisabled, setIsMotherTabDisabled] = useState(true);
    const [isGuardiantabDisabled, setIsGuardianTabDisabled] = useState(true);
    const [isFinalSubmit, setIsFinalSubmit] = useState(false);
    function handleStudentFormChange(newValue) {
        
        for (var key in newValue['values']) {
            values[key] = newValue['values'][key];
        }
        setKey('father-tab');
        setIsFatherTabDisabled(false);
        setIsFinalSubmit(false);
    }
    function handleFatherFormChange(newValue) {
        for (var key in newValue['values']) {
            values[key] = newValue['values'][key];
        }
        setKey('mother-tab');
        setIsMotherTabDisabled(false);
        setIsFinalSubmit(false);
    }
    function handleMotherFormChange(newValue) {
        for (var key in newValue['values']) {
            values[key] = newValue['values'][key];
        }
        setKey('guardian-tab');
        setIsGuardianTabDisabled(false);
        setIsFinalSubmit(false);
    }
    function handleGuardiantFormChange(newValue) {
        setIsFinalSubmit(false);
        for (var key in newValue['values']) {
            values[key] = newValue['values'][key];
        }
        setIsFinalSubmit(true);
    }
    
    const [key, setKey] = useState('student-tab');
    useEffect(() => {
        if (isFinalSubmit) {
            
            var bodyFormData = new FormData();
            bodyFormData.append('id', values.id);
            bodyFormData.append('enrolmentNo', values.enrolmentNo);
            bodyFormData.append('firstName', values.firstName);
            bodyFormData.append('lastName', values.lastName);
            bodyFormData.append('dob', values.dob);
            bodyFormData.append('addressLine', values.addressLine);
            bodyFormData.append('addressLine2', values.addressLine2);
            // bodyFormData.append('class', values.class);
            // bodyFormData.append('section', values.section);
            bodyFormData.append('phoneNo', values.phoneNo);
            bodyFormData.append('pinCode', values.pinCode);
            bodyFormData.append('state', values.state);
            bodyFormData.append('city', values.city);
            bodyFormData.append('fatherFirstName', values.fatherFirstName);
            bodyFormData.append('fatherLastName', values.fatherLastName);
            bodyFormData.append('fatherMobileNo', values.fatherMobileNo);
            bodyFormData.append('fatherEmail', values.fatherEmail);
            bodyFormData.append('motherFirstName', values.motherFirstName);
            bodyFormData.append('motherLastName', values.motherLastName);
            bodyFormData.append('motherMobileNo', values.motherMobileNo);
            bodyFormData.append('motherEmail', values.motherEmail);
            bodyFormData.append('guardianFirstName', values.guardianFirstName);
            bodyFormData.append('guardianLastName', values.guardianLastName);
            bodyFormData.append('guardianMobileNo', values.guardianMobileNo);
            bodyFormData.append('guardianEmail', values.guardianEmail);
            bodyFormData.append('guardianProfileVisibility', values.guardianProfileVisibility);
            bodyFormData.append('studentProfilePic', values.studentProfilePic);
            bodyFormData.append('fatherProfilePic', values.fatherProfilePic);
            bodyFormData.append('motherProfilePic', values.motherProfilePic);
            bodyFormData.append('guardianProfilePic', values.guardianProfilePic);
            
            // 

            utils.callAPI('post', 'students/save', bodyFormData).then(
                res => {
                    setIsFinalSubmit(false);
                    if (typeof res !== 'undefined') {
                        const propdata = {
                            'mode': 'list',
                        }
                        props.onChange(propdata);
                    }
                }
            )
        }
    }, [isFinalSubmit]);
    useEffect(() => {
        //
        //
        // if(props.rowData && Object.keys(props.rowData).length > 0) {
        //     
        //     values['rowid'] = props.rowData.id;
        //     values['empNo'] = props.rowData.empNo;
        //     values['firstName'] = props.rowData.firstName;
        //     values['lastName'] = props.rowData.lastName;
        //     values['designation'] = props.rowData.designation;
        //     values['mobileNo'] = props.rowData.mobileNo;
        //     values['schoolId'] = localStorage.getItem('currentSchool');
        //     setIsEdit(true)
        // }
    }, []);
    return (
        <>
            <div className="section-body">
                <div className="card card-primary">
                    <div className="card-header">
                        <h4>Add Student</h4>
                        <div className="card-header-action">
                            <button className="btn btn-outline-primary" onClick={handleBackClick}>
                                Back
                            </button>
                        </div>
                    </div>
                    <div className='card-body'>
                            <Tabs
                                id="controlled-tab-example"
                                activeKey={key}
                                onSelect={(k) => setKey(k)}
                            >
                                <Tab eventKey="student-tab" title="Student">
                                    <StudentForm onChange={handleStudentFormChange} rowData={props.rowData}/>
                                </Tab>
                                <Tab eventKey="father-tab" title="Father" disabled={isFathertabDisabled}>
                                    <FatherForm onChange={handleFatherFormChange} rowData={props.rowData}/>
                                </Tab>
                                <Tab eventKey="mother-tab" title="Mother" disabled={isMothertabDisabled}>
                                    <MotherForm onChange={handleMotherFormChange} rowData={props.rowData}/>
                                </Tab>
                                <Tab eventKey="guardian-tab" title="Guardian" disabled={isGuardiantabDisabled}>
                                    <GuardianForm onChange={handleGuardiantFormChange} rowData={props.rowData}/>
                                </Tab>
                            </Tabs>
                        
                    </div>

                </div>
            </div>
        </>
    );
}


export default Add