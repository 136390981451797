import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import * as utils from "../../Utils/comman";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import ButtonLoader from "../ButtonLoader";

function List(props) {
  const [classes, setClasses] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [section, setSection] = useState([]);
  const [selectedClass, setSelectedClass] = useState();
  const [selectedSection, setSelectedSection] = useState();
  const [isClassSelected, setIsClassSelected] = useState(false);
  const [dateMonth, setDateMonth] = useState();
  const [excelData, setExcelData] = useState();
  const [attendanceExcel, setAttendanceExcel] = useState([]);
  const [error, setError] = useState({});
  const [isExcel, setIsExcel] = useState(false);
  const [selectedClassLabel, setSelectedClassLabel] = useState();
  const [selectedSectionLabel, setSelectedSectionLabel] = useState();
  const [isSubmittingBtn, setIsSubmittingBtn] = useState(false);

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  useEffect(() => {
    getClassList();
  }, []);

  function getClassList() {
    utils.callAPI("get", "classes/list", "").then((res) => {
      if (typeof res !== "undefined") {
        if (Object.keys(res.result).length > 0) {
          setClasses(
            res.result.map(({ id, className }) => ({
              label: className,
              value: id,
            }))
          );
        }
      }
    });
  }

  const handleClass = (e) => {
    setSelectedClassLabel(classes.find((x) => x.value === e.target.value));
    setSelectedClass(e.target.value);
    setIsClassSelected(true);
  };

  useEffect(() => {
    if (isClassSelected) {
      getSectionList(selectedClass);
    }
  }, [isClassSelected]);

  function getSectionList(selClass) {
    utils.callAPI("get", `section/list?classId=${selClass}`, "").then((res) => {
      if (typeof res !== "undefined") {
        if (Object.keys(res.result).length > 0) {
          setSection(
            res.result.map(({ id, section }) => ({ label: section, value: id }))
          );
          // setSelectedClass('');
          setIsClassSelected(false);
        } else {
          setSection([]);
          setSelectedClass("");
          setIsClassSelected(false);
        }
      }
    });
  }
  const handleSection = (e) => {
    setSelectedSectionLabel(section.find((x) => x.value === e.target.value));
    setSelectedSection(e.target.value);
  };

  const handleMonth = (e) => {
    setDateMonth(moment(e.target.value).format("MMMM YYYY"));
  };

  const attendanceReport = (e) => {
    if (selectedClass && selectedSection && dateMonth !== undefined) {
      setError("");
      setIsSubmittingBtn(true)
      const bodyFormData = new FormData();
      bodyFormData.append("classId", parseInt(selectedClass));
      bodyFormData.append("sectionId", parseInt(selectedSection));
      bodyFormData.append("month_year", dateMonth);
      utils
        .callAPI("post", "/attendance/attendance-report", bodyFormData)
        .then((res) => {
          if (typeof res !== "undefined") {
            setExcelData(res.report);
            setIsSubmitting(true);
          }
        });
    } else {
      setError(validate(selectedClass, selectedSection, dateMonth));
    }
    e.preventDefault();
  };

  useEffect(() => {
    if (isSubmitting) {
      excelData?.map((data, id) => {
        const oneAttendance = {};
        const studentName = data.fullName;
        data?.attendance?.map((attendance, id) => {
          oneAttendance["Name"] = studentName;
          var formateDate = moment(attendance.date).format("DD");
          var now = {};
          now[`${formateDate} `] = attendance.type;
          Object.assign(oneAttendance, now);
        });
        const newArray = oneAttendance;
        setAttendanceExcel((current) => [...current, newArray]);
      });
      setIsSubmitting(false);
      setIsExcel(true);
    }
  }, [isSubmitting]);

  useEffect(() => {
    if (isExcel) {
      if (Object.keys(attendanceExcel).length !== 0) {
        const headere = [
          ["Class", `${selectedClassLabel.label}`],
          ["Section", `${selectedSectionLabel.label}`],
          ["Month-year", `${dateMonth}`],
        ];
        // const header = [
        //   {'Class':`${selectedClassLabel.label}`, 'Section':`${selectedSectionLabel.label}`,},
        // ];
        let ws = XLSX.utils.aoa_to_sheet(headere);
        // let ws = XLSX.utils.json_to_sheet(attendanceExcel);
        XLSX.utils.sheet_add_json(ws, attendanceExcel, { origin: -1 });
        var wscols = [{ wch: 17 }];
        ws["!cols"] = wscols;
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, {
          bookType: "xlsx",
          type: "array",
          cellDates: true,
        });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(
          data,
          `attendance_report ${dateMonth}` + fileExtension
        );
      }
      setIsSubmittingBtn(false)
      setClasses([""]);
      setSection([""]);
      setSelectedClass();
      setSelectedSection();
      setDateMonth();
      setIsExcel(false);
      setAttendanceExcel([]);
      getClassList();
    }
  }, [isExcel]);

  function validate(selectedClass, selectedSection, dateMonth) {
    let error = {};
    if (selectedClass == undefined) {
      error.class = "Please select class";
    }
    if (selectedSection == undefined) {
      error.section = "Please select Section";
    }
    if (dateMonth == undefined) {
      error.month = "Please select Month-Year";
    }

    return error;
  }

  return (
    <>
      <div className="section-body">
        <div className="card card-primary">
          <div className="card-header">
            <h4>Attendance Report</h4>
          </div>
          <form onSubmit={attendanceReport}>
            <div className="card-body">
              <div className="row align-items-center">
                <div className="col-md-3">
                  <label htmlFor="class">Class</label>
                  <select
                    id="class"
                    name="class"
                    className="form-control"
                    onChange={handleClass}
                  >
                    <option value="">Select Class</option>
                    {classes.reverse().map(({ label, value }, id) => (
                      <option key={label} value={value}>
                        {label}
                      </option>
                    ))}
                  </select>
                  {error.class && (
                    <div className="invalid-feedback d-block">
                      {error.class}
                    </div>
                  )}
                </div>
                <div className="col-md-3">
                  <label htmlFor="class">Section</label>
                  <select
                    id="class"
                    name="class"
                    className="form-control"
                    onChange={handleSection}
                  >
                    <option value="">Select Section</option>
                    {section?.reverse().map(({ label, value }) => (
                      <option key={value} value={value}>
                        {label}
                      </option>
                    ))}
                  </select>
                  {error.section && (
                    <div className="invalid-feedback d-block">
                      {error.section}
                    </div>
                  )}
                </div>
                <div className="col-md-3">
                  <label htmlFor="dob">Month-Year</label>
                  <input
                    type="month"
                    className="form-control"
                    onChange={handleMonth}
                  />
                  {error.month && (
                    <div className="invalid-feedback d-block">
                      {error.month}
                    </div>
                  )}
                </div>
                <div className="col-md-2 mt-4">
                  <button
                    type="submit"
                    className="btn btn-primary btn-lg btn-icon icon-right d-flex align-items-center justify-content-center"
                    disabled={isSubmittingBtn}
                  >
                    {isSubmittingBtn ? <ButtonLoader /> : null}
                    <span className={isSubmittingBtn ? "ml-2" : ""}>Submit</span>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default List;
