import React from 'react'
import { useEffect } from 'react';
import { useMemo } from 'react';
import { useCallback } from 'react';
import { useState } from 'react';
import DataTable from 'react-data-table-component';
import swal from 'sweetalert';
import * as utils from '../../Utils/comman';
import FilterComponent from '../FilterComponent';

function ViewStudent(props) {

    const [students, setUsers] = useState({});    
    const [page, setPage] = useState(1);
    const countPerPage = 10;
    const [filterText, setFilterText] = useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const columns = [
        {
            name: 'Student Name',
            cell: row => <>

            {(row.typeId === "1" || row.typeId === "2") ? <span className="text-primary">{row.fullName}</span> : row.fullName}
            </>,
        },
        {
            name: 'Action',
            cell: row => <>
            {(row.typeId === "1" || row.typeId === "2") ? '' : <button className='btn btn-sm btn-outline-danger' onClick={showDelete(row)} ><i className="fas fa-trash-alt"></i></button>}
            </>
        },
    ];


    useEffect(() => {
        getStudentList();
    }, []);
    
    
    const getStudentList = (sectionId) => {
        utils.callAPI('get', `/section/students/sectionStudentList?sectionId=${props.rowData.id}`, '').then(
            res => {
                if (typeof res !== 'undefined') {
                    setUsers(res);
                } else {
                    setUsers({});
                }
            }
        ).catch(err => {
            setUsers({});
        });
    }

    const showDelete = useCallback(
        row => async () => {
            // 
            // 
            swal({
                title: 'Are you sure?',
                text: 'Once deleted, you will not be able to recover this data!',
                icon: 'warning',
                buttons: ["No", "Yes"],
                dangerMode: true,
            })
            .then((willDelete) => {
                if (willDelete) {
                    utils.callAPI('get', `section/students/removeStudent?id=${row.id}`, '').then(
                        res => {
                            
                            if (typeof res !== 'undefined') {
                                // getTeachersList(props.rowData.id);
                                getStudentList();
                            }
            
                        }
                    )
                } 
            });
        }
    )

    const handleBackClick = (e) => {
        const propdata = {
            mode: "list",
        };
        props.onChange(propdata);
    };

    const handleAddClick = (e) => {
        const propdata = {
          mode: "studentAdd",
          rowData: props.rowData.id,
        };
        props.onChange(propdata);
      };

      const subHeaderComponent = useMemo(() => {
        
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText("");
            }
        };
        
        return (
            <FilterComponent    
            onFilter={e => setFilterText(e.target.value)}
            onClear={handleClear}
            filterText={filterText}
            />
        );
    }, [filterText, resetPaginationToggle]);
  return (
    <>
        <div className="section-body">
                <div className="card card-primary">
                    <div className="card-header">
                        <h4>View Student</h4>
                        <div className="card-header-action">
                            <button className="btn btn-primary mr-2"  onClick={handleAddClick}>
                                Add Student
                            </button>
                            <button
                                className="btn btn-outline-primary"
                                onClick={handleBackClick}
                            >
                                Back
                            </button>
                        </div>
                    </div>
                    <div className="card-body">
                        <DataTable
                            columns={columns}
                            data={students.result}
                            highlightOnHover
                            responsive
                            pagination
                            paginationServer
                            paginationTotalRows={students.total}
                            paginationPerPage={countPerPage}
                            paginationComponentOptions={{
                                noRowsPerPage: true
                            }}
                            onChangePage={page => setPage(page)}
                            persistTableHead
                            subHeader
                            subHeaderComponent={subHeaderComponent}
                        />
                        
                        
                    </div>
                </div>
            </div>
    </>
  )
}

export default ViewStudent